import { createAsyncThunk } from "@reduxjs/toolkit";
import { agentsApi, participantsApi, propertyApi } from "../../api";
import {
  AgentAppointment,
  BenefitToAgent,
  Fee,
  Participant,
  Participant1ParticipantTypeEnum,
  Participant1RoleEnum,
  Property,
} from "../../generated";
import { authority, client_id, konvei_api } from "../../constants";
import { User } from "oidc-client-ts";
import axios from "axios";

export interface GetAgentAppointmentResponse {
  agentAppointment: AgentAppointment;
  fees: Array<Fee>;
  benefits: Array<BenefitToAgent>;
  clients: Array<Participant>;
  licencee: Participant;
  agent: Participant;
  property: Property;
}

export const getAgentAppointment = createAsyncThunk<
  GetAgentAppointmentResponse,
  number
>("agentAppointment/getAgentAppointment", async (workspaceId) => {
  const response: GetAgentAppointmentResponse = {
    licencee: {},
    agent: {},
    agentAppointment: {},
    clients: [],
    fees: [],
    benefits: [],
    property: {},
  };

  const agentResponsePromise = await agentsApi.getAgentAppointment(workspaceId);
  const feesPromise = await agentsApi.getFees(workspaceId);
  const benefitsPromise = await agentsApi.getBenefitToAgents(workspaceId);
  const propertyPromise = await propertyApi.getProperty(workspaceId);

  const [agentsAppointment, fees, benefits, property] = await Promise.all([
    agentResponsePromise,
    feesPromise,
    benefitsPromise,
    propertyPromise,
  ]);
  response.agentAppointment = agentsAppointment.data;
  if (fees.status == 200) {
    response.fees = fees.data.items!;
  }
  if (benefits.status == 200) {
    response.benefits = benefits.data.items!;
  }
  response.property = await property.data;

  const participantsResponse = await participantsApi.getParticipants(
    workspaceId
  );

  const licencee = participantsResponse.data.items!.find(
    (x) => x.role == Participant1RoleEnum.Licencee
  );

  if (licencee) {
    response.licencee = licencee;
  } else {
    const p: Participant = {
      id: 0,
      role: Participant1RoleEnum.Licencee,
      participantType: Participant1ParticipantTypeEnum.Organisation,
    };
    const resp = await participantsApi.postParticipant(workspaceId, p);
    p.id = resp.data.id;
    response.agent = p;
  }

  const agent = participantsResponse.data.items!.find(
    (x) => x.role == Participant1RoleEnum.SellerAgent
  );
  if (agent) {
    response.agent = agent;
  } else {
    const p: Participant = {
      id: 0,
      role: Participant1RoleEnum.SellerAgent,
      participantType: Participant1ParticipantTypeEnum.Organisation,
    };
    response.agent = p;
  }

  const sellers = participantsResponse.data.items!.filter(
    (x) => x.role == "seller"
  );
  if (sellers) {
    response.clients = sellers;
  }

  return response;
});

export interface UpdateAgentAppointmentArgs {
  workspaceId: number;
  agentAppointment: AgentAppointment;
}
export const updateAgentAppointment = createAsyncThunk<
  AgentAppointment,
  UpdateAgentAppointmentArgs
>(
  "agentAppointment/updateAgentAppointment",
  async ({ workspaceId, agentAppointment: agent }) => {
    await agentsApi.putAgentAppointment(workspaceId, agent);
    return agent;
  }
);

export interface ParticipantArgs {
  workspaceId: number;
  participant: Participant;
}
export const updateParticipant = createAsyncThunk<Participant, ParticipantArgs>(
  "agentAppointment/updateParticipant",
  async ({ workspaceId, participant }) => {
    if (!participant.id || participant.id == 0) {
      await participantsApi.postParticipant(workspaceId, participant);
    } else {
      await participantsApi.putParticipant(
        workspaceId,
        participant.id!,
        participant
      );
    }
    return participant;
  }
);

export const addSeller = createAsyncThunk<number, number>(
  "agentAppointment/addSeller",
  async (workspaceId) => {
    const p: Participant = {
      id: 0,
      role: "seller",
      participantType: Participant1ParticipantTypeEnum.Individual,
    };
    return (await addParticipant(workspaceId, p)).data.id!;
  }
);

const addParticipant = async (
  workspaceId: number,
  participant: Participant
) => {
  const result = await participantsApi.postParticipant(
    workspaceId,
    participant
  );
  return result;
};

export const addLicencee = createAsyncThunk<number, number>(
  "agentAppointment/addLicencee",
  async (workspaceId) => {
    const p: Participant = {
      id: 0,
      role: Participant1RoleEnum.Licencee,
      participantType: Participant1ParticipantTypeEnum.Organisation,
    };
    return (await addParticipant(workspaceId, p)).data.id!;
  }
);

export interface SavePropertyArgs {
  workspaceId: number;
  property: Property;
}

export const saveProperty = createAsyncThunk<void, SavePropertyArgs>(
  "agentAppointment/saveProperty",
  async ({ workspaceId, property }) => {
    await propertyApi.putProperty(workspaceId, property);
  }
);

export const getAgentAppointmentPDF = createAsyncThunk<string, number>(
  "agentAppointment/getAgentAppointmentPDF",
  async (workspaceId) => {
    const oidcStorage = sessionStorage.getItem(
      `oidc.user:${authority}:${client_id}`
    );
    const user = User.fromStorageString(oidcStorage!);

    const response = await axios.get(
      `${konvei_api}/workspaces/${workspaceId}/agent-appointment/pdf`,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(response.data);

    return url;
  }
);

export interface DeleteParticipantArgs {
  workspaceId: number;
  participantId: number;
}
export const deleteParticipant = createAsyncThunk<
  number,
  DeleteParticipantArgs
>(
  "agentAppointment/deleteParticipant",
  async ({ workspaceId, participantId }) => {
    await participantsApi.deleteParticipant(workspaceId, participantId);
    return participantId;
  }
);

export const addFee = createAsyncThunk<number, number>(
  "agentAppointment/addFee",
  async (workspaceId) => {
    const id = await agentsApi.postFee(workspaceId, {});
    return id.data.id!;
  }
);

export interface UpdateFeeArgs {
  workspaceId: number;
  fee: Fee;
}
export const updateFee = createAsyncThunk<Fee, UpdateFeeArgs>(
  "agentAppointment/updateFee",
  async ({ workspaceId, fee }) => {
    await agentsApi.updateFee(workspaceId, fee.id, fee);
    return fee;
  }
);

export const deleteFee = createAsyncThunk<Fee, UpdateFeeArgs>(
  "agentAppointment/deleteFee",
  async ({ workspaceId, fee }) => {
    await agentsApi.deleteFee(workspaceId, fee.id);
    return fee;
  }
);

export const addBenefitToAgent = createAsyncThunk<number, number>(
  "agentAppointment/addBenefitToAgent",
  async (workspaceId) => {
    const id = await agentsApi.postBenefitToAgent(workspaceId, {});
    return id.data.id!;
  }
);

export interface UpdateBenefitToAgentArgs {
  workspaceId: number;
  benefitToAgent: BenefitToAgent;
}
export const updateBenefitToAgent = createAsyncThunk<
  BenefitToAgent,
  UpdateBenefitToAgentArgs
>(
  "agentAppointment/updateBenefitToAgent",
  async ({ workspaceId, benefitToAgent }) => {
    await agentsApi.updateBenefitToAgent(
      workspaceId,
      benefitToAgent.id,
      benefitToAgent
    );
    return benefitToAgent;
  }
);
export const deleteBenefitToAgent = createAsyncThunk<
  BenefitToAgent,
  UpdateBenefitToAgentArgs
>(
  "agentAppointment/deleteBenefitToAgent",
  async ({ workspaceId, benefitToAgent }) => {
    await agentsApi.deleteBenefitToAgent(workspaceId, benefitToAgent.id);
    return benefitToAgent;
  }
);

export interface CompleteTaskArgs {
  workspaceId: number;
  taskName: string;
}

export const sendAgentAppointmentForSigning = createAsyncThunk<void, number>(
  "agentAppointment/sendForSigning",
  async (workspaceId) => {
    await agentsApi.sendAgentAppointmentForSigning(workspaceId);
  }
);
