import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { authority, client_id, redirectUri } from "./constants.ts";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import CssBaseline from "@mui/material/CssBaseline";
import { User } from "oidc-client-ts";

const oidcConfig: AuthProviderProps = {
  authority: authority,
  client_id: client_id,
  redirect_uri: redirectUri,
  automaticSilentRenew: true,

  onSigninCallback: (_user: User | void): void => {
    
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <AuthProvider {...oidcConfig}>
    <CssBaseline />
    <App />
  </AuthProvider>
);
