const authority = (window as any)["AUTHORITY"];
const client_id = (window as any)["CLIENT_ID"];
const redirectUri = (window as any)["REDIRECT_URI"];
const konvei_api = (window as any)["KONVEI_MATTERS_URI"];
const events_uri = (window as any)["EVENTS_URI"];
const conveyancing_documents_api = (window as any)[
  "KONVEI_CONVEYANCING_DOCUMENTS_URI"
];
export {
  authority,
  client_id,
  konvei_api,
  conveyancing_documents_api,
  redirectUri,
  events_uri,
};
