import Grid from "@mui/material/Grid";
import React from "react";
import ParticipantCard from "../../../components/participant-card";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import { useAppSelector } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";

const SellerAgent: React.FC = () => {
  const state = useAppSelector(contractStepperState);

  return (
    <FormWrapper title="Seller's Agent">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ParticipantCard participant={state.sellerAgent}></ParticipantCard>
        </Grid>
      </Grid>
    </FormWrapper>
  );
};

export default SellerAgent;
