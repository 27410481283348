import { Box } from "@mui/material";
import LCButton from "../../components/button";
import LCCard from "../../components/cards/lc-card";
import OfferTable from "../../components/offer-table";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { offersState, OffersStatus } from "./offers-slice";
import { useEffect } from "react";
import { createOffer, getOffers, getWorkspace } from "./thunks";
import { AddOutlined } from "@mui/icons-material";
import { sendToSellerForSigning } from "../workspace/thunks";

const Offers = () => {
  const state = useAppSelector(offersState);
  const params = useParams();
  const dispatch = useAppDispatch();
  const workspaceId = Number(params.workspaceId);

  const navigate = useNavigate();

  useEffect(() => {
    if (state.status == OffersStatus.initial) {
      dispatch(getOffers(workspaceId));
      dispatch(getWorkspace(workspaceId));
    }
  }, [state.status, dispatch]);

  useEffect(() => {
    if (state.newOfferId) {
      navigate(`${state.newOfferId}`);
    }
  }, [state.newOfferId, dispatch]);

  function handleOnOfferCreated(): void {
    dispatch(createOffer(workspaceId));
  }

  function handleOnSendToSeller(offerId: number): void {
    dispatch(
      sendToSellerForSigning({ workspaceId: workspaceId, offerId: offerId })
    );
  }
  function handleOnSendToBuyer(_: number): void {
    throw new Error("Function not implemented.");
  }

  return (
    <LCCard
      title="Offers"
      txtColor="#000"
      titleColor="#000"
      bgColor="#fff"
      buttons={
        <Box sx={{ position: "relative", zIndex: 1 }}>
          {params["offerId"] == null &&
          state.workspace.workspace!.role == "sellerAgent" ? (
            <Box sx={{ width: 120 }}>
              <LCButton
                label="Offer"
                color="#2e7d32"
                onClick={handleOnOfferCreated}
                startIcon={<AddOutlined />}
              />
            </Box>
          ) : null}
        </Box>
      }
    >
      <OfferTable
        offers={state.offers}
        workspace={state.workspace.workspace!}
        onOfferClicked={(offerId) => {
          navigate(offerId);
        }}
        // onOfferClicked={onOfferClicked}
        onSendToSeller={handleOnSendToSeller}
        onSendToBuyer={handleOnSendToBuyer}
        workflow={state.workflow}
      />
    </LCCard>
  );
};

export default Offers;
