import { createAsyncThunk } from "@reduxjs/toolkit";
import { usersApi } from "../../api";

export const assertUser = createAsyncThunk<boolean, void>(
  "auth-callback/assertUser",
  async () => {
    const response = await usersApi.assertUser();

    return response.data.newUser;
  }
);
