import Grid from "@mui/material/Grid";
import React from "react";
import Typography from "@mui/material/Typography";
import { Participant } from "../../../generated";
import { Box, useTheme } from "@mui/material";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import ParticipantNameForm from "../../../components/participant-name-form";
import FormInputText from "../../../components/form-input-text";
import ContactDetails from "../../../components/contact-details";
import AddressForm from "../../../components/address-form";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";
import { getParams } from "../helpers";
import { saveContractParticipant } from "../thunks";
import AutoSave from "../../../components/auto-save";

const BuyersSolicitor: React.FC = () => {
  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const workspaceParams = getParams(params);

  const disableField = ["sellerAgent"].includes(
    state.workspace.workspace?.role!
  );

  const byerSolicitorFormMethods = useForm<Participant>({
    defaultValues: state.buyerSolicitor,
    // resolver: yupResolver(schema),
  });

  const { handleSubmit, control, setValue } = byerSolicitorFormMethods;

  const theme = useTheme();

  // useEffect(() => {
  //   reset(participant);
  //   trigger();
  // }, [participant]);

  const handleSaveContractParticipant: SubmitHandler<Participant> = (data) => {
    dispatch(
      saveContractParticipant({
        workspaceId: workspaceParams.workspaceId,
        offerId: workspaceParams.offerId,
        participant: data,
      })
    );
  };

  return (
    <FormProvider {...byerSolicitorFormMethods}>
      <form onSubmit={handleSubmit(handleSaveContractParticipant)}>
        <FormWrapper title="Buyers' Solicitor's Details">
          <Box
            sx={{
              maxWidth: "1024px",
              margin: "15px auto",
              padding: "30px",
              background: "#faf7f7",
              borderRadius: "30px",
              boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormInputText
                  control={control}
                  name="solicitorName"
                  label="Solicitor Name"
                  required
                  disabled={disableField}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <ParticipantNameForm
                  control={control}
                  participantType={"organisation"}
                  disabled={disableField}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Contact Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ContactDetails control={control} disabled={disableField} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Address
                </Typography>
              </Grid>
              <AddressForm
                control={control}
                address={state.buyerSolicitor}
                setValue={setValue}
                disabled={disableField}
              />
            </Grid>
          </Box>
        </FormWrapper>
      </form>
      <AutoSave
        onSubmit={handleSaveContractParticipant}
        defaultValues={state.buyerSolicitor}
      ></AutoSave>
    </FormProvider>
  );
};

export default BuyersSolicitor;
