import {jwtDecode} from "jwt-decode";
import { User } from "oidc-client-ts";
import { authority, client_id } from "../constants";

let roles = Array<string>();
function getRoles(): string[] {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${authority}:${client_id}`
  );

  if (oidcStorage) {
    const user = User.fromStorageString(oidcStorage);
    const token = jwtDecode(user.access_token, {}) as string;

    const roles = JSON.parse(JSON.stringify(token))["roles"];
    return roles;
  }
  return [];
}

const getTenant = (): string => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${authority}:${client_id}`
  );

  if (oidcStorage) {
    const user = User.fromStorageString(oidcStorage);
    const token = jwtDecode(user.access_token, {}) as string;
    const tenant = JSON.parse(JSON.stringify(token))["tenant"] as string;

    if (tenant === undefined) {
      return ""
    } 
    return tenant as string;
  }
  return "";
};

function hasRole(requiredRole: string): boolean {
  if (roles.length == 0) {
    roles = getRoles();
  }

  return roles.some((v) => v == requiredRole);
}

export { getRoles, hasRole, getTenant };
