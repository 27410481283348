import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, ReactElement } from "react";
import DocumentsTable, {
  OnDocumentClicked,
} from "../../../components/documents-table";
import {
  DocumentSummary,
  OfferDetail,
  Participant,
  Workflow,
  WorkspaceRoleEnum,
  WorkspaceSummary,
} from "../../../generated";
import {
  OnInviteBuyerSolicitor,
  OnInviteSolicitor,
  OnTaskOpened,
} from "../events";
import { OnOfferClicked, OnWorkspaceEvent } from "../../../components/events";
import LCCard from "../../../components/cards/lc-card";
import { WorkspaceState } from "../workspace-slice";
import ActivityLog from "./activity-log";
import ParticipantList from "./participants-list";
import BottomNavToggle from "./bottom-nav";
import { Outlet } from "react-router-dom";
import SelectSolicitor, { ActingForEnum } from "./actions/select-solicitor";
import LCButton from "../../../components/button";
import { useAppSelector } from "../../../app/hooks";
import { sessionState } from "../../session/session-slice";
import Timeline from "../../timeline/timeline";

export interface WorkspaceViewProps {
  state: WorkspaceState;
  header: ReactElement;
  participants: Participant[];
  documents: DocumentSummary[];
  workspaceSummary: WorkspaceSummary;
  onTaskOpened: OnTaskOpened;
  onOfferClicked: OnOfferClicked;
  onOfferCreated?: OnWorkspaceEvent;
  onDocumentClicked: OnDocumentClicked;
  onInviteSellerSolicitor: OnInviteSolicitor;
  onInviteBuyerSolicitor: OnInviteBuyerSolicitor;
  onSendToSeller: OnOfferClicked;
  onSendToBuyer: OnOfferClicked;
  onShareWithSeller: OnOfferClicked;
  offers?: OfferDetail[];
  showOffers: boolean;
  workflow: Workflow;
}

const WorkspaceView: FC<WorkspaceViewProps> = ({
  state,
  participants,
  workspaceSummary,
  onDocumentClicked,
  documents = [],
  workflow,
  onInviteSellerSolicitor,
  onInviteBuyerSolicitor,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sessState = useAppSelector(sessionState);
  const filterParticipantsByRole = (participant: Participant) => {
    return (
      (participant.role === "seller" ||
        participant.role === "sellerSolicitor" ||
        participant.role === "buyer" ||
        participant.role === "buyerSolicitor") &&
      participant.phone
    );
  };

  const timelineIsVisible =
    state.workspace.workspace?.role == WorkspaceRoleEnum.SellerAgent ||
    state.workspace.workspace?.role == WorkspaceRoleEnum.SellerSolicitor;

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <Grid container>
          <Grid
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            {timelineIsVisible && (
              <Box sx={{ width: 800 }}>
                <Timeline  workflow={workflow}/>
              </Box>

            )}
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "row", xs: "column-reverse" },
                gap: 2,
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  flex: "10",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {state.workflow?.subState ==
                "agentAppointmentAwaitingSigning" ? (
                  <LCCard
                    title={""}
                    titleColor="#000"
                    txtColor="#000"
                    bgColor="#fff"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      minHeight="200px"
                    >
                      <CircularProgress />
                      <Typography variant="h6" style={{ marginTop: "16px" }}>
                        Waiting for Seller to Sign
                      </Typography>
                    </Box>
                  </LCCard>
                ) : state.workflow?.subState ==
                  "agentAppointmentAwaitingSellerAdmission" ? (
                  <LCCard
                    title={""}
                    titleColor="#000"
                    txtColor="#000"
                    bgColor="#fff"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      minHeight="200px"
                    >
                      <CircularProgress />
                      <Typography variant="h6" style={{ marginTop: "16px" }}>
                        Waiting for Seller to Accept the Invitation
                      </Typography>
                    </Box>
                  </LCCard>
                ) : (
                  <Outlet></Outlet>
                )}
                {state.workflow?.subState ==
                 
                  "contractPreparationAwaitingSellerSolicitorSelection" &&
                  (state.workspace.workspace?.role == "seller" ||
                    state.workspace.workspace?.role == "buyer") && (
                    <SelectSolicitor
                      actingFor={
                        state.workspace.workspace?.role == "seller"
                          ? ActingForEnum.Seller
                          : ActingForEnum.Buyer
                      }
                      onInviteBuyerSolicitor={onInviteBuyerSolicitor}
                      onInviteSellerSolicitor={onInviteSellerSolicitor}
                    />
                  )}
              </Box>
              <Box
                sx={{
                  margin: "0 auto",
                  position: { md: "sticky", xs: "relative" },
                  top: { md: 89, xs: 0 },
                  flex: "3",
                  width: { md: "auto", xs: "100%" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <LCCard
                    title={"Offer Status"}
                    bgColor="#fff"
                    titleColor="#000"
                    txtColor="#000"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        padding: "10px",
                      }}
                    >
                      <Box>
                        <strong>Buyer:</strong>
                        <Box
                          sx={{
                            display: "inline",
                            color: "green",
                            marginLeft: 1,
                          }}
                        >
                          ready
                        </Box>
                      </Box>
                      <Box>
                        <strong>Seller:</strong>{" "}
                        <Box
                          sx={{
                            display: "inline",
                            color: "orange",
                            marginLeft: 1,
                          }}
                        >
                          pending
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        padding: "10px",
                      }}
                    >
                      <Box sx={{ width: 80, zIndex: 1 }}>
                        <LCButton label="Edit" color="#000" size="small" />
                      </Box>
                      <Box sx={{ width: 80, zIndex: 1 }}>
                        <LCButton label="Done" color="#8F1219" size="small" />
                      </Box>
                    </Box>
                  </LCCard>

                  <LCCard
                    title={`Workspace`}
                    titleColor="#000"
                    bgColor="#fff"
                    txtColor="#000"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "30px",
                          marginBottom: 2,
                          width: {
                            xlg: "700px",
                            lg: "500px",
                            md: "300px",
                            sm: "100%",
                          },
                        }}
                      >
                        <img
                          src={`https://landconnex-prod-public.ap-south-1.linodeobjects.com/${workspaceSummary.workspace?.id}.jpeg`}
                          alt="property-image"
                          style={{ borderRadius: "12px", width: "100%" }}
                        />
                        <Box>
                          <Typography
                            variant="h5"
                            component="span"
                            className="roboto-bold"
                          >
                            {`${workspaceSummary.property?.streetAddress1},
      ${workspaceSummary.property?.locality} ${workspaceSummary.property?.stateOrTerritory},
      ${workspaceSummary.property?.postCode}`}
                          </Typography>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="body1"
                              component="span"
                              sx={{ color: "#3e3e3e" }}
                            >
                              Lot:{" "}
                              <Typography variant="body1" component="span">
                                {workspaceSummary.property?.lot}
                              </Typography>
                            </Typography>

                            <Typography
                              variant="body1"
                              component="span"
                              sx={{
                                color: "#3e3e3e",
                                ml: 1,
                                "::before": {
                                  content: "'•'",
                                  marginRight: "8px",
                                },
                              }}
                            >
                              Plan:{" "}
                              <Typography variant="body1" component="span">
                                {workspaceSummary.property?.plan}
                              </Typography>
                            </Typography>

                            <Typography
                              variant="body1"
                              component="span"
                              sx={{
                                color: "#3e3e3e",
                                ml: 1,
                                "::before": {
                                  content: "'•'",
                                  marginRight: "8px",
                                },
                              }}
                            >
                              Title Reference:{" "}
                              <Typography variant="body1" component="span">
                                {workspaceSummary.property?.titleReference}
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        borderTop: "1px solid silver",
                        marginTop: 3,
                        marginBottom: 2,
                      }}
                    />

                    <ParticipantList
                      participants={participants}
                      currentUserEmail={sessState.details?.email!}
                      roles={[
                        "sellerAgent",
                        "seller",
                        "sellerSolicitor",
                        "buyer",
                        "buyerSolicitor",
                      ]}
                    />
                  </LCCard>
                  {documents.length >= 1 && (
                    <LCCard
                      title={"Documents"}
                      txtColor="#273E47"
                      bgColor="#014597"
                      circleElementBefore="#4a84ad"
                      circleElementAfter="#023676"
                    >
                      <DocumentsTable
                        onDocumentViewClicked={onDocumentClicked}
                        documents={documents}
                      />
                    </LCCard>
                  )}

                  <LCCard
                    title={"Activity Log"}
                    bgColor="#fff"
                    titleColor="#000"
                  >
                    <ActivityLog activityLog={state.activityLog} />
                  </LCCard>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {isMobile && (
        <Box sx={{ position: "relative" }}>
          <BottomNavToggle
            participants={state.participants}
            filterFunction={filterParticipantsByRole}
          />
        </Box>
      )}
    </Grid>
  );
};

export default WorkspaceView;
