import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  AgentAppointment,
  BenefitToAgent,
  Fee,
  Participant,
  Property,
} from "../../generated";
import {
  addBenefitToAgent,
  addFee,
  addSeller,
  deleteBenefitToAgent,
  deleteFee,
  deleteParticipant,
  getAgentAppointment,
  getAgentAppointmentPDF,
  sendAgentAppointmentForSigning,
  updateAgentAppointment,
  updateBenefitToAgent,
  updateFee,
  updateParticipant,
} from "./thunks";
import dayjs from "dayjs";

export enum AgentAppointmentStatus {
  initial,
  loading,
  ready,
  dirty,
  updated,
  pdfReadyToDownload,
  sentForSigning,
}
export enum GeneratedPDFStatus {
  initial,
  loading,
  ready,
  stale,
}

interface AgentAppointmentState {
  status: AgentAppointmentStatus;
  pdfStatus: GeneratedPDFStatus;
  property: Property;
  agentAppointment: AgentAppointment;
  fees: Array<Fee>;
  benefits: Array<BenefitToAgent>;
  clients: Array<Participant>;
  licencee: Participant;
  licenceeAgent: Participant;
  pdf?: string;
  pdfName?: string;
  step: string;
  workspaceId: number;
}

const initialState: AgentAppointmentState = {
  status: AgentAppointmentStatus.initial,
  pdfStatus: GeneratedPDFStatus.initial,
  agentAppointment: {},
  fees: [],
  benefits: [],
  property: {},
  clients: [],
  licencee: {},
  licenceeAgent: {},
  step: "client",
  workspaceId: 0,
};

export const agentAppointmentSlice = createSlice({
  name: "agentAppointment",
  initialState,
  reducers: {
    initialise: (state, action: PayloadAction<number>) => {
      state.workspaceId = action.payload;
      state.status = AgentAppointmentStatus.initial;
    },
    moveToStep: (state, action: PayloadAction<string>) => {
      state.step = action.payload;
    },
    participantChanged: (state, action: PayloadAction<Participant>) => {
      const index = state.clients.findIndex((v) => v.id == action.payload.id);
      console.log(state.clients);
      state.clients[index] = action.payload;
      console.log(state.clients);
      state.status = AgentAppointmentStatus.dirty;
    },
    licenceeChanged: (state, action: PayloadAction<Participant>) => {
      state.licencee = action.payload;
      state.status = AgentAppointmentStatus.dirty;
    },
    licenceeAgentChanged: (state, action: PayloadAction<Participant>) => {
      state.licenceeAgent = action.payload;
      state.status = AgentAppointmentStatus.dirty;
    },

    propertyUpdated: (state, action: PayloadAction<Property>) => {
      state.property = action.payload;
      state.status = AgentAppointmentStatus.dirty;
    },
  },
  extraReducers: (builder) => {
    GetAgentAppointmentReducers(builder);
    GetAgentAppointmentPdfReducers(builder);
    AddSellerReducers(builder);
    UpdateAgentAppointmentReducers(builder);
    UpdateParticipantReducer(builder);
    DeleteParticipantReducer(builder);
    FeeReducer(builder);
    BenefitToAgentReducer(builder);
    SendAgentAppointmentReducer(builder);
  },
});

function SendAgentAppointmentReducer(
  builder: ActionReducerMapBuilder<AgentAppointmentState>
) {
  builder.addCase(sendAgentAppointmentForSigning.fulfilled, (state) => {
    state.status = AgentAppointmentStatus.sentForSigning;
  });
}

function FeeReducer(builder: ActionReducerMapBuilder<AgentAppointmentState>) {
  builder.addCase(addFee.fulfilled, (state, action) => {
    state.status = AgentAppointmentStatus.ready;
    const fee: Fee = { id: action.payload };
    state.fees.push(fee);
  });
  builder.addCase(updateFee.fulfilled, (state, action) => {
    const fees = state.fees;
    const index = fees.findIndex((x) => x.id == action.payload.id);
    state.fees[index] = action.payload;
  });
  builder.addCase(deleteFee.fulfilled, (state, action) => {
    const fees = state.fees;
    const index = fees.findIndex((x) => x.id == action.payload.id);
    state.fees.splice(index, 1);
  });
}

function BenefitToAgentReducer(
  builder: ActionReducerMapBuilder<AgentAppointmentState>
) {
  builder.addCase(addBenefitToAgent.fulfilled, (state, action) => {
    state.status = AgentAppointmentStatus.ready;
    const benefitToAgent: BenefitToAgent = { id: action.payload };
    state.benefits.push(benefitToAgent);
  });
  builder.addCase(updateBenefitToAgent.fulfilled, (state, action) => {
    const benefitToAgents = state.benefits;
    const index = benefitToAgents.findIndex((x) => x.id == action.payload.id);
    state.benefits[index] = action.payload;
  });
  builder.addCase(deleteBenefitToAgent.fulfilled, (state, action) => {
    const benefitToAgents = state.benefits;
    const index = benefitToAgents.findIndex((x) => x.id == action.payload.id);
    state.benefits.splice(index, 1);
  });
}

function UpdateAgentAppointmentReducers(
  builder: ActionReducerMapBuilder<AgentAppointmentState>
) {
  builder.addCase(updateAgentAppointment.pending, (state) => {
    state.status = AgentAppointmentStatus.loading;
  });
  builder.addCase(updateAgentAppointment.fulfilled, (state, action) => {
    state.agentAppointment = action.payload;
    state.status = AgentAppointmentStatus.updated;
  });
}

function UpdateParticipantReducer(
  builder: ActionReducerMapBuilder<AgentAppointmentState>
) {
  builder.addCase(updateParticipant.pending, (state) => {
    state.status = AgentAppointmentStatus.loading;
  });
  builder.addCase(updateParticipant.fulfilled, (state, action) => {
    const index = state.clients.findIndex(
      (client) => client.id === action.payload.id
    );

    // Check if the client is found
    if (index !== -1) {
      // Replace the client at the found index with the updated client
      state.clients[index] = action.payload;
    }
    state.status = AgentAppointmentStatus.ready;
    //state.pdfStatus = GeneratedPDFStatus.stale;
  });
}

function DeleteParticipantReducer(
  builder: ActionReducerMapBuilder<AgentAppointmentState>
) {
  builder.addCase(deleteParticipant.fulfilled, (state, action) => {
    state.clients.splice(
      state.clients.findIndex((v) => v.id == action.payload),
      1
    );

    state.status = AgentAppointmentStatus.ready;
    //state.pdfStatus = GeneratedPDFStatus.stale;
  });
}

function GetAgentAppointmentReducers(
  builder: ActionReducerMapBuilder<AgentAppointmentState>
) {
  builder.addCase(getAgentAppointment.pending, (state) => {
    state.status = AgentAppointmentStatus.loading;
  });
  builder.addCase(getAgentAppointment.fulfilled, (state, action) => {
    state.agentAppointment = action.payload.agentAppointment;
    if (state.agentAppointment.appointmentTermStart) {
      const start = dayjs(Date.now());
      const end = start.add(90, "day");
      state.agentAppointment.appointmentTermStart = start.toISOString();
      state.agentAppointment.appointmentTermEnd = end.toISOString();
    }
    state.licencee = action.payload.licencee;
    state.licenceeAgent = action.payload.agent;
    state.fees = action.payload.fees;

    state.clients = action.payload.clients;
    state.status = AgentAppointmentStatus.ready;
    //state.pdfStatus = GeneratedPDFStatus.stale;

    state.property = action.payload.property;
  });
}

function GetAgentAppointmentPdfReducers(
  builder: ActionReducerMapBuilder<AgentAppointmentState>
) {
  builder.addCase(getAgentAppointmentPDF.pending, (state) => {
    state.pdfStatus = GeneratedPDFStatus.loading;
  });
  builder.addCase(getAgentAppointmentPDF.fulfilled, (state, action) => {
    state.pdfName = toKebabCase(
      `agent appointment ${state.property.streetAddress1} ${state.property.locality}`
    );
    state.pdf = action.payload;
    state.pdfStatus = GeneratedPDFStatus.ready;
  });
}

function toKebabCase(input: string): string {
  return input
    .trim() // Remove leading and trailing whitespaces
    .replace(/[^\w\s]/g, "") // Remove any punctuation and special characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .toLowerCase(); // Convert everything to lowercase
}

function AddSellerReducers(
  builder: ActionReducerMapBuilder<AgentAppointmentState>
) {
  builder.addCase(addSeller.pending, (_state) => {
    //state = initialState;
  });
  builder.addCase(addSeller.fulfilled, (state, action) => {
    state.clients.push({ id: action.payload, role: "seller" });
    state.status = AgentAppointmentStatus.ready;
    //state.pdfStatus = GeneratedPDFStatus.stale;
  });
}

export const {
  moveToStep,
  initialise,
  participantChanged,
  licenceeChanged,
  licenceeAgentChanged,
  propertyUpdated,
} = agentAppointmentSlice.actions;
export default agentAppointmentSlice.reducer;
export const agentAppointmentState = (state: RootState) =>
  state.agentAppointment;
