import CircularProgress from "@mui/material/CircularProgress";
import { FC, Fragment, useEffect } from "react";
import PDFViewer from "../../../components/pdf-viewer";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "./generate.css";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  agentAppointmentState,
  AgentAppointmentStatus,
  GeneratedPDFStatus,
} from "../agent-appointment-slice";
import {
  getAgentAppointmentPDF,
  sendAgentAppointmentForSigning,
} from "../thunks";
import StatusMessage from "./status-message";

export interface GenerateProps {
  url: string;
  fileName: string;
  isLoading: boolean;
}

const Generate: FC = () => {
  const state = useAppSelector(agentAppointmentState);
  const params = useParams();
  const dispatch = useAppDispatch();
  const workspaceId = Number(params.workspaceId);

  useEffect(() => {
    if (
      state.pdfStatus == GeneratedPDFStatus.initial ||
      state.pdfStatus == GeneratedPDFStatus.stale
    ) {
      dispatch(getAgentAppointmentPDF(workspaceId));
    }
  }, [state.pdfStatus, dispatch]);

  function handleOnSendAgentAppointmentForSigning(): void {
    dispatch(sendAgentAppointmentForSigning(workspaceId));
  }

  return (
    <Grid container>
      {state.status == AgentAppointmentStatus.loading ? (
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress size={100}></CircularProgress>
        </Grid>
      ) : null}
      {state.status != AgentAppointmentStatus.loading ? (
        <Fragment>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
          >
            <Grid item xs={12} sm={6}>
              <StatusMessage
                status={state.status}
                onSendAgentAppointmentForSigning={
                  handleOnSendAgentAppointmentForSigning
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            padding={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid id="pdf" item padding={2}>
              <Paper square>
                <Grid item xs={12} className="generate-container">
                  <PDFViewer url={state.pdf!} />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Fragment>
      ) : null}
    </Grid>
  );
};

export default Generate;
