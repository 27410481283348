import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const RedirectToLogin = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <CircularProgress />
      <Typography variant="h6" style={{ marginTop: "16px" }}>
        Redirecting to login. Please wait...
      </Typography>
    </Box>
  );
};

export default RedirectToLogin;