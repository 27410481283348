import Grid from "@mui/material/Grid";
import React, { useCallback } from "react";
import FormInputText from "../../../components/form-input-text";
import { Box, Typography, debounce, useTheme } from "@mui/material";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Contract } from "../../../generated";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";
import { saveContract } from "../thunks";
import { getParams } from "../helpers";
import { useParams } from "react-router-dom";

const SpecialConditions: React.FC = ({}) => {
  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const workspaceParams = getParams(params);

  const { control, formState, handleSubmit } = useForm<any>({
    defaultValues: state.contract,
  });
  const theme = useTheme();

  // useEffect(() => {
  //   reset(contract);
  //   trigger();
  // }, [contract]);

  const onSubmit: SubmitHandler<Contract> = (data) => {
    dispatch(
      saveContract({
        workspaceId: workspaceParams.workspaceId,
        offerId: workspaceParams.offerId,
        contract: data,
      })
    );
  };

  const watchedData = useWatch({
    control: control,
    defaultValue: state.contract,
  });

  const debouncedSave = useCallback(
    debounce(() => {
      console.log("Saving");
      handleSubmit(onSubmit)();
    }, 1000),
    []
  );
  useDeepCompareEffect(() => {
    console.log("Triggered");
    if (formState.isDirty) {
      debouncedSave();
    }
  }, [watchedData]);

  return (
    <FormWrapper title="Special Conditions">
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Special Conditions
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInputText
              control={control}
              name="specialConditions"
              label="Special Conditions"
              multiline
              minRows={4}
            ></FormInputText>
          </Grid>
        </Grid>
      </Box>
    </FormWrapper>
  );
};

export default SpecialConditions;
