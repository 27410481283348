import { Grid, Box } from "@mui/material";
import React, { Fragment } from "react";
import { Participant } from "../../../generated";
import ClientDetail from "./client-detail";
import LCButton from "../../../components/button";
import FormWrapper from "./form-wrapper";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { agentAppointmentState } from "../agent-appointment-slice";
import { addSeller, deleteParticipant, updateParticipant } from "../thunks";

export type OnParticipantAdded = () => void;

const ClientDetails: React.FC = () => {
  const state = useAppSelector(agentAppointmentState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);

  const notes = [
    {
      title: "Note:",
      items: [
        "The client is the person or entity appointing the agent to provide the services. This may be the owner (or authorised representative of the owner) of the land, property or business that is to be sold or may be a prospective buyer seeking to purchase land or a property.",
        "Fields marked with * are required.",
        "Annexures detailing additional clients may be attached if required.",
      ],
    },
  ];
  function onRemoved(participant: Participant): void {
    dispatch(
      deleteParticipant({
        workspaceId: workspaceId,
        participantId: participant.id!,
      })
    );
  }
  function onChanged(participant: Participant): void {
    dispatch(
      updateParticipant({ workspaceId: workspaceId, participant: participant })
    );
  }

  function onAdded(): void {
    dispatch(addSeller(workspaceId));
  }

  return (
    <FormWrapper title="Client Details" notes={notes}>
      <Fragment>
        {state.clients.map((client, index) => (
          <ClientDetail
            key={client.id}
            client={client}
            index={index}
            onChanged={onChanged}
            onDelete={onRemoved}
          />
        ))}

        <Grid container spacing={2} justifyContent={"center"}>
          <Box sx={{ width: "220px", marginTop: 3 }}>
            <LCButton
              label="add another client"
              color="#000"
              onClick={() => onAdded()}
            />
          </Box>
        </Grid>
      </Fragment>
    </FormWrapper>
  );
};

export default ClientDetails;
