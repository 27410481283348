import { Workflow } from "../../generated";
import { NavigateFunction, Location } from "react-router-dom";

const navigateToWorkflowStep = (
  workspaceRole: string,
  workflow: Workflow,
  navigate: NavigateFunction,
  location: Location
) => {
  console.log(location);
  // const pathSegments = location.pathname.split("/").filter(Boolean);
  // const lastSegment = pathSegments[pathSegments.length - 1] || "/";

  // switch (lastSegment) {
  //   case "contract":
  //     if (workspaceRole == "seller" || workspaceRole == "sellerSolicitor") {
  //       return;
  //     }
  //     navigate(".");
  //     break;
  //   case "agent-appointment":
  //     if (workspaceRole == "sellerAgent") {
  //       return;
  //     }
  //     navigate(".");
  //     break;

  //   default:
  //     break;
  // }

  switch (workflow.state) {
    case "agentAppointment":
      navigate("agent-appointment");
      break;
    case "contractPreparation":
      if (workspaceRole == "sellerAgent") {
        navigate("offers");
        break;
      }
      if (workspaceRole == "sellerSolicitor") {
        navigate("contract");
        break;
      }
      break;
    case "acceptingOffers":
      navigate("offers");
      break;
    default:
      break;
  }
};
export default navigateToWorkflowStep;
