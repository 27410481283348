import * as React from "react";
import { styled, CSSObject, Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  sessionState,
  toggleDrawer,
  toggleModal,
} from "../features/session/session-slice";
import { Fragment } from "react";

interface DrawerProps {
  open: boolean;
  toggleDrawer: () => void;
}

interface Route {
  name: string;
  path: string;
}

const drawerWidth = 200;

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const CustomDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const KonveiMiniDrawer: React.FC<DrawerProps> = ({ open }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useAppSelector(sessionState);
  const auth = useAuth();

  const navItems: Route[] = [{ name: "Workspaces", path: "/workspaces" }];

  return (
    <CustomDrawer
      variant="permanent"
      open={state.drawerOpen}
      sx={{
        "& .MuiDrawer-paper": {
          border: "none",
          background: "#fff",
        },
      }}
    >
      <Box>
        <DrawerHeader>
          <Box
            onClick={() => dispatch(toggleDrawer())}
            component="div"
            sx={{ flexGrow: 1, display: open ? "block" : "none" }}
          >
            <Typography
              className="logo roboto-bold"
              variant="h5"
              sx={{
                color: "#8F1219",
                fontFamily: "Roboto",
                fontWeight: "700",
                fontStyle: "normal",
              }}
            >
              LandConnex
            </Typography>
          </Box>
          <IconButton onClick={() => dispatch(toggleDrawer())}>
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
        </DrawerHeader>
        <Typography
          variant="inherit"
          component="section"
          sx={{
            margin: "10px 0px 0.35em",
            fontFamily: "Roboto, sans-serif",
            lineHeight: 1.66,
            display: "block",
            color: "rgb(18, 25, 38)",
            fontSize: "0.875rem",
            fontWeight: 500,
            padding: "6px",
            textTransform: "capitalize",
          }}
        >
          Pages
        </Typography>
        <List>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItemButton>
          {navItems.map((item, index) => (
            <ListItemButton
              key={item.name}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <ListItemIcon>
                {index % 2 === 0 ? (
                  <WorkspacesOutlinedIcon />
                ) : (
                  <AddCircleOutlineOutlinedIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          ))}
          {/* {state.isWorkspaceCreator && ( */}
          <ListItemButton onClick={() => dispatch(toggleModal())}>
            <ListItemIcon>
              <AddCircleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"New"} />
          </ListItemButton>
          {/* )} */}
          <ListItemButton
            onClick={() => {
              navigate("/create-workspace");
            }}
          >
            <ListItemIcon>
              <AddCircleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"New"} />
          </ListItemButton>
        </List>
        <Divider />
        <Typography
          variant="inherit"
          component="section"
          sx={{
            margin: "10px 0px 0.35em",
            fontFamily: "Roboto, sans-serif",
            lineHeight: 1.66,
            display: "block",
            color: "rgb(18, 25, 38)",
            fontSize: "0.875rem",
            fontWeight: 500,
            padding: "6px",
            textTransform: "capitalize",
          }}
        >
          Utilities
        </Typography>
        <List>
          {state.isWorkspaceAdministrator ? (
            <Fragment>
              <ListItemButton
                onClick={() => {
                  navigate("/manage-users");
                }}
              >
                <ListItemIcon>
                  <ManageAccountsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Users" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/settings");
                }}
              >
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </Fragment>
          ) : (
            <></>
          )}

          <ListItemButton onClick={() => auth.signoutRedirect()}>
            <ListItemIcon>
              <ExitToAppOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Box>
    </CustomDrawer>
  );
};

export default KonveiMiniDrawer;
