import Container from "@mui/material/Container";
import { OfferDraftStatus, offerDraftState } from "./offer-drafts-slice";
import { getPDF, saveName } from "./thunks";
import {
  Box,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./offer-draft-page.css";
import PDFViewer from "../../components/pdf-viewer";
import { useDebounce } from "usehooks-ts";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  sendToBuyerForSigning,
  sendToSellerForSigning,
  shareWithSeller,
} from "../workspace/thunks";
import LCButton from "../../components/button";
import { cancelOffer, inviteBuyers } from "../offer-stepper/thunks";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useParams } from "react-router-dom";
import { contractStepperState } from "../contract-stepper/contract-stepper-slice";

const OfferDraftView: React.FC = () => {
  const state = useAppSelector(offerDraftState);
  const stateRole = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();
  const nameUpdatedDebounce = useDebounce(state.offer.id, 1000);
  const params = useParams();
  const workspaceId = Number(params.workspaceId);
  const offerId = Number(params.offerId);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const [validationErrors, _] = useState<{ message: string; step: number }[]>(
  //   []
  // );

  const handleMenuItemClick = (action: any) => {
    if (action === "inviteBuyers") {
      handleInviteBuyers(offerId);
    } else if (action === "sendToSeller") {
      handleSendToSellers(offerId);
    } else if (action === "shareWithSeller") {
      handleShareWithSeller(offerId);
    } else if (action === "sendToBuyer") {
      handleSendToBuyer(offerId);
    } else if (action === "cancelOffer") {
      handleCancelOffer(offerId);
    }
    handleClose();
  };

  useEffect(() => {
    dispatch(
      getPDF({
        workspaceId: workspaceId,
        offerId: offerId,
      })
    );
  }, [workspaceId, offerId]);

  useEffect(() => {
    if (
      state.status != OfferDraftStatus.ready &&
      state.status != OfferDraftStatus.pdfReadyToDownload
    ) {
      return;
    }
    saveName({
      workspaceId: Number(workspaceId),
      offer: state.offer,
    });
  }, [nameUpdatedDebounce]);

  function handleSendToBuyer(offerId: number): void {
    dispatch(
      sendToBuyerForSigning({
        workspaceId: workspaceId,
        offerId: offerId,
      })
    );
  }

  function handleInviteBuyers(offerId: number): void {
    dispatch(
      inviteBuyers({
        workspaceId: workspaceId,
        offerId: offerId,
      })
    );
  }

  function handleSendToSellers(offerId: number): void {
    dispatch(
      sendToSellerForSigning({
        workspaceId: workspaceId,
        offerId: offerId,
      })
    );
  }

  function handleShareWithSeller(offerId: number): void {
    dispatch(
      shareWithSeller({
        workspaceId: workspaceId,
        offerId: offerId,
      })
    );
  }
  function handleCancelOffer(offerId: number): void {
    dispatch(
      cancelOffer({
        workspaceId: workspaceId,
        offerId: offerId,
      })
    );
  }

  console.log(stateRole);

  return (
    <Container style={{ marginTop: 30 }}>
      {state.status == OfferDraftStatus.initial ||
      state.status == OfferDraftStatus.loadingPending ? (
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress size={100}></CircularProgress>
        </Grid>
      ) : null}

      {state.status == OfferDraftStatus.pdfReadyToDownload ? (
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 2,
          }}
        >
          {/* {validationErrors.length == 0 && ( */}
          <Box
            sx={{
              display: "flex",
              alignSelf: "center",
            }}
          >
            {stateRole.workspace.workspace?.role === "sellerAgent" && (
              <Box
                sx={{
                  width: "150px",
                }}
              >
                <LCButton
                  label="Actions"
                  color="#8F1219"
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(e) => {
                    handleClick(e);
                  }}
                />
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem onClick={() => handleMenuItemClick("inviteBuyers")}>
                    Invite Buyers
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("sendToBuyer")}>
                    Send to Buyer
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("sendToSeller")}>
                    Send to Seller
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleMenuItemClick("shareWithSeller")}
                  >
                    Share to Seller
                  </MenuItem>

                  <MenuItem onClick={() => handleMenuItemClick("cancelOffer")}>
                    Cancel Offer
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
          {/* )} */}
          <Paper square sx={{ alignSelf: "center" }}>
            <PDFViewer url={state.pdfUrl} />
          </Paper>
        </Grid>
      ) : null}
    </Container>
  );
};

export default OfferDraftView;
