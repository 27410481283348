import Box from "@mui/material/Box";
import { useAppDispatch } from "../../app/hooks";
import { FormProvider, useForm } from "react-hook-form";
import FormInputText from "../../components/form-input-text";
import Button from "@mui/material/Button";
import { startSubscription } from "./thunks";
import LCCard from "../../components/cards/lc-card";
import { Typography } from "@mui/material";

interface CustomerInformation {
  accountName: string;
}
const CustomerInformation = () => {
  const dispatch = useAppDispatch();
  const formMethods = useForm<CustomerInformation>();
  const { handleSubmit, control } = formMethods;

  const handleStartSubscription = (data: CustomerInformation) => {
    dispatch(startSubscription(data.accountName));
  };
  return (
    <LCCard
      title={"Subscriptions"}
      titleColor="#000"
      txtColor="#000"
      bgColor="#fff"
    >
      <Box sx={{ maxWidth: 1000, margin: "0 auto" }}>
        <Typography variant="h6" mb={3}>
          Please tell us your account name.
        </Typography>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleStartSubscription)}>
            <FormInputText
              control={control}
              name="accountName"
              label="Account Name"
              required={true}
            />
            <Box sx={{ width: 100, margin: "15px auto" }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  background: "#8F1219",
                  borderColor: "#8F1219",
                  width: "100%",
                  height: 50,
                  borderRadius: 30,
                  fontSize: 16,
                }}
              >
                Next
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </LCCard>
  );
};

export default CustomerInformation;
