import { FC } from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./events";
import CurrencyField from "./currency-field";

interface ExtendedFormInputProps extends FormInputProps {
  disabled?: boolean;
}

const FormInputNumeric: FC<ExtendedFormInputProps> = ({
  name,
  label,
  control,
  required,
  disabled, // default value for disabled
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <CurrencyField
          label={label}
          amount={value}
          required={required}
          disabled={disabled} // pass disabled to CurrencyField
          onChange={(amount) => {
            onChange(amount);
          }}
        />
      )}
    />
  );
};

export default FormInputNumeric;
