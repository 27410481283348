import { useAuth } from "react-oidc-context";

const Login = () => {
  const auth = useAuth();

  return (
    <div>
      <pre>{JSON.stringify(auth, null, 2)}</pre>

      <button
        onClick={() => {
          void auth.signinRedirect();
        }}
      >
        Log in
      </button>
    </div>
  );
};

export default Login;
