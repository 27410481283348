import * as React from "react";
import { Box, LinearProgress, LinearProgressProps } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";

const stripes = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 100%;
  }
`;

const BorderLinearProgress = styled(LinearProgress)(({}) => ({
  height: 8,
  borderRadius: 5,
  backgroundImage: `repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 0.6rem,
      #1976d266 0.6rem,
      #1976d266 1.2rem
    )`,
  backgroundSize: `200% 200%`,
  animation: `${stripes} 8s linear infinite`,
  "& .MuiLinearProgress-bar": {
    backgroundColor: "transparent", // Set the filling color to transparent
  },
}));

const LCLinearProgressBar: React.FC<LinearProgressProps> = (props) => {
  return (
    <Box sx={{ width: "100%" }}>
      <BorderLinearProgress {...props} />
    </Box>
  );
};

export default LCLinearProgressBar;
