import Grid from "@mui/material/Grid";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { DateField } from "@mui/x-date-pickers/DateField";
import { Box, Typography, useTheme } from "@mui/material";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Contract } from "../../../generated";
import { contractStepperState } from "../contract-stepper-slice";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { saveContract } from "../thunks";
import { useParams } from "react-router-dom";
import AutoSave from "../../../components/auto-save";
import { getParams } from "../helpers";

const Settlement: React.FC = () => {
  const theme = useTheme();
  const state = useAppSelector(contractStepperState);
  const params = useParams();

  const workspaceParams = getParams(params);
  const dispatch = useAppDispatch();

  // function onSettlementDateChanged(value: Dayjs | null): void {
  //   const newOffer = { ...state.contract };
  //   if (value) {
  //     newOffer.settlementDate = value.toISOString();
  //   } else {
  //     newOffer.settlementDate = "0001-01-01T00:00:00Z";
  //   }
  //   onOfferChanged(newOffer);
  // }

  // function onOfferChanged(contract: Contract): void {
  //   contract.depositHolder = "test";
  //   dispatch(updateContract(contract));
  // }

  const contractFormMethods = useForm<Contract>({
    defaultValues: state.contract,
  });

  const { control, handleSubmit } = contractFormMethods;

  const onContractChangedSubmit = (contract: Contract) => {
    const newOffer = { ...state.contract };
    if (contract.settlementDate) {
      const d: unknown = contract.settlementDate;
      let f = d as Dayjs;
      newOffer.settlementDate = f.toISOString();
    } else {
      newOffer.settlementDate = "0001-01-01T00:00:00Z";
    }
    newOffer.depositHolder = "test";

    dispatch(
      saveContract({
        workspaceId: workspaceParams.workspaceId,
        offerId: workspaceParams.offerId,
        contract: newOffer,
      })
    );
  };

  return (
    <FormProvider {...contractFormMethods}>
      <form onSubmit={handleSubmit(onContractChangedSubmit)}>
        <FormWrapper title="Settlement Date">
          <Box
            sx={{
              maxWidth: "1024px",
              margin: "15px auto",
              padding: "30px",
              background: "#faf7f7",
              borderRadius: "30px",
              boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Settlement Date
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Controller
                  control={control}
                  name="settlementDate"
                  render={({ field }) => (
                    <DateField
                      variant="filled"
                      size="small"
                      label="Settlement Date"
                      {...field}
                      value={
                        state.contract.settlementDate
                          ? dayjs(state.contract.settlementDate)
                          : null
                      }
                      //  onChange={onSettlementDateChanged}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <AutoSave
              onSubmit={onContractChangedSubmit}
              defaultValues={state.contract}
            />
          </Box>
        </FormWrapper>
      </form>
    </FormProvider>
  );
};

export default Settlement;
