import { FC, Fragment, useState } from "react";
import { Activity, Contract } from "../../../generated";
import {
  OnInviteSolicitor,
  OnTaskCompleted,
  OnWorkspaceAction,
} from "../events";
import { OnDocumentClicked } from "../../../components/documents-table";
import { WorkspaceState } from "../workspace-slice";
import { OnOfferClicked, OnWorkflowChanged } from "../../../components/events";
import { OnCompleteAgentAppointment } from "./complete-agent-appointment-uploaded-dialog";
import "./agent-view.css";
import AgentHeader from "./agent-header";
import SendAgentAppointmentDialog from "./send-agent-appointment-page";
import { useNavigate } from "react-router-dom";
import CompleteAgentAppointmentUploadedDialog from "./complete-agent-appointment-uploaded-dialog";
import WorkspaceView from "./workspace-view";
import { inviteBuyerSolicitor, inviteSellerSolicitor } from "../thunks";
import { useAppDispatch } from "../../../app/hooks";

export type OnOffer = (offer?: Contract) => void;

export interface AgentWorkspaceViewProps {
  state: WorkspaceState;
  onOfferCreate?: OnOffer;
  onOfferEdit?: OnOffer;
  onEditAgentAppointment: OnWorkspaceAction;
  onEditContract: OnWorkspaceAction;
  onNewOffer: OnWorkspaceAction;
  onWorkflowChanged: OnWorkflowChanged;
  onOfferClicked: OnOfferClicked;
  onCompleteAgentAppointment: OnCompleteAgentAppointment;
  onDocumentView: OnDocumentClicked;
  onSendAgentAppointment: OnWorkspaceAction;
  onTaskCompleted: OnTaskCompleted;
  onInviteSellerSolicitor: OnInviteSolicitor;
  onInviteBuyerSolicitor: OnInviteSolicitor;
  onSendToSeller: OnOfferClicked;
  onSendToBuyer: OnOfferClicked;
  onShareWithSeller: OnOfferClicked;
}

const AgentWorkspaceView: FC<AgentWorkspaceViewProps> = ({
  state,
  onOfferClicked,
  onDocumentView,
  onSendAgentAppointment,
  onTaskCompleted,
  onOfferCreate,
  onSendToSeller,
  onSendToBuyer,
  onShareWithSeller,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function handleTaskOpened(task: Activity): void {
    switch (task.name) {
      case "publish-agent-appointment":
        navigate("agent-appointment");
        break;

      case "send-agent-appointment-for-signing":
        setSendAgentAppointmentDialogOpen(true);
        break;
      case "agent-appointment-signed":
        setCompleteAgentAppointmentUploadedDialogOpen(true);
        break;
      case "publish-contract":
        navigate("contract");
        break;
      default:
        break;
    }
  }
  const [sendAgentAppointmentDialogOpen, setSendAgentAppointmentDialogOpen] =
    useState(false);
  const [
    completeAgentAppointmentUploadedDialogOpen,
    setCompleteAgentAppointmentUploadedDialogOpen,
  ] = useState(false);

  function handleInviteSellerSolicitor(emailAddress: string): void {
    dispatch(
      inviteSellerSolicitor({
        workspaceId: state.workspace.workspace!.id,
        emailAddress: emailAddress,
      })
    );
  }

  function handleInviteBuyerSolicitor(
    offerId: number,
    emailAddress: string
  ): void {
    dispatch(
      inviteBuyerSolicitor({
        workspaceId: state.workspace.workspace!.id,
        offerId: offerId,
        emailAddress: emailAddress,
      })
    );
  }

  return (
    <Fragment>
      <WorkspaceView
        header={
          <AgentHeader
            workspaceSummary={state.workspace}
            participants={state.participants}
            workflow={state.workflow!}
          />
        }
        offers={state.offers}
        workflow={state.workflow!}
        participants={state.participants}
        state={state}
        documents={state.documents}
        workspaceSummary={state.workspace}
        onTaskOpened={handleTaskOpened}
        onOfferClicked={onOfferClicked}
        onDocumentClicked={onDocumentView}
        showOffers={state.workflow!.state != "agentAppointment"}
        onOfferCreated={onOfferCreate}
        onSendToSeller={onSendToSeller}
        onInviteSellerSolicitor={handleInviteSellerSolicitor}
        onInviteBuyerSolicitor={handleInviteBuyerSolicitor}
        onSendToBuyer={onSendToBuyer}
        onShareWithSeller={onShareWithSeller}
      />

      <SendAgentAppointmentDialog
        open={sendAgentAppointmentDialogOpen}
        onDownload={() => {
          setSendAgentAppointmentDialogOpen(false);
          onSendAgentAppointment(state.workspace);
        }}
        onCancel={() => {
          setSendAgentAppointmentDialogOpen(false);
        }}
      />
      <CompleteAgentAppointmentUploadedDialog
        open={completeAgentAppointmentUploadedDialogOpen}
        onCancel={() => {
          setCompleteAgentAppointmentUploadedDialogOpen(false);
        }}
        onComplete={(_) => {
          setCompleteAgentAppointmentUploadedDialogOpen(false);
          onTaskCompleted({
            id: 0,
            name: "",
            isComplete: false,
          });
        }}
      />
    </Fragment>
  );
};

export default AgentWorkspaceView;
