import { Box, colors, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import TimelineStage from "./timeline-stage";
import { Workflow } from "../../generated";

export interface TimelineProps {
  workflow: Workflow;
}

interface StepInfo {
  steps: number;
  currentStep: number;
}

const subStateMapping: Record<string, Record<string, string>> = {
  agentAppointment: {
    agentAppointmentPreparing: "Preparing",
    agentAppointmentAwaitingSigning: "Awaiting Signing",
    agentAppointmentAwaitingSellerAdmission: "Awaiting Seller Admission",
    agentAppointmentCompleted: "Completed",
  },
  contractPreparation: {
    contractPreparationAwaitingSellerSolicitorSelection:
      "Awaiting Solicitor Selection",
    contractPreparationAwaitingSellerSolicitorAdmission:
      "Awaiting Solicitor Admission",
    contractPreparationPreparing: "Preparing",
    contractPreparationCompleted: "Completed",
  },
  acceptingOffers: {
    acceptingOffers: "Accepting Offers",
    contractSigned: "Contract Signed",
  },
  contractSigned: {
    contractSignedCompleted: "Contract Signed",
  },
};

const Timeline: FC<TimelineProps> = ({ workflow }) => {
  const [aaStepInfo, setAAStepInfo] = useState<StepInfo>({
    steps: 4,
    currentStep: 1,
  });
  const [cpStepInfo, setCPStepInfo] = useState<StepInfo>({
    steps: 4,
    currentStep: 1,
  });
  const [oStepInfo, setOStepInfo] = useState<StepInfo>({
    steps: 2,
    currentStep: 1,
  });

  useEffect(() => {
    const stepInfo: StepInfo = { steps: 0, currentStep: 0 };
    switch (workflow.state) {
      case "agentAppointment":
        stepInfo.steps = 4;
        if (workflow.subState === "agentAppointmentPreparing") {
          stepInfo.currentStep = 1;
        } else if (workflow.subState === "agentAppointmentAwaitingSigning") {
          stepInfo.currentStep = 2;
        } else if (
          workflow.subState === "agentAppointmentAwaitingSellerAdmission"
        ) {
          stepInfo.currentStep = 3;
        } else {
          stepInfo.currentStep = 4;
        }
        setAAStepInfo(stepInfo);
        setCPStepInfo({ steps: 0, currentStep: 0 });
        setOStepInfo({ steps: 0, currentStep: 0 });
        break;

      case "contractPreparation":
        stepInfo.steps = 4;
        if (
          workflow.subState ===
          "contractPreparationAwaitingSellerSolicitorSelection"
        ) {
          stepInfo.currentStep = 1;
        } else if (
          workflow.subState ===
          "contractPreparationAwaitingSellerSolicitorAdmission"
        ) {
          stepInfo.currentStep = 2;
        } else if (workflow.subState === "contractPreparationPreparing") {
          stepInfo.currentStep = 3;
        } else {
          stepInfo.currentStep = 4;
        }
        setAAStepInfo({ steps: 1, currentStep: 1 });
        setCPStepInfo(stepInfo);
        setOStepInfo({ steps: 0, currentStep: 0 });
        break;

      case "acceptingOffers":
        stepInfo.steps = 2;
        if (workflow.subState === "acceptingOffers") {
          stepInfo.currentStep = 1;
        } else if (workflow.subState === "contractSigned") {
          stepInfo.currentStep = 2;
        }
        setAAStepInfo({ steps: 1, currentStep: 1 });
        setCPStepInfo({ steps: 1, currentStep: 1 });
        setOStepInfo(stepInfo);
    }
  }, [workflow]);

  const getSubStateLabel = (state: string): string => {
    if (workflow.state === "acceptingOffers") {
      if (state === "agentAppointment" || state === "contractPreparation") {
        return "Completed";
      }
    }

    if (workflow.state === "contractPreparation") {
      if (state === "agentAppointment") {
        return "Completed";
      }
    }

    return subStateMapping[state]?.[workflow.subState] || "Not Started";
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          flexDirection: "column",
          margin: "20px 0 50px 0",
        }}
      >
        <Typography variant="body1" fontWeight="bold">
          Agent Appointment
        </Typography>
        <Typography variant="caption" mb={2}>
          {getSubStateLabel("agentAppointment")}
        </Typography>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "10px",
            background: colors.grey[300],
            borderRadius: "5px 0 0 5px",
          }}
        >
          <TimelineStage
            primaryColor={"green"}
            steps={aaStepInfo.steps}
            currentStep={aaStepInfo.currentStep}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          flexDirection: "column",
          margin: "20px 0 50px 0",
        }}
      >
        <Typography variant="body1" fontWeight="bold">
          Contract Preparation
        </Typography>
        <Typography variant="caption" mb={2}>
          {getSubStateLabel("contractPreparation")}
        </Typography>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "10px",
            background: colors.grey[300],
            borderRadius: "0",
          }}
        >
          <TimelineStage
            primaryColor={"orange"}
            steps={cpStepInfo.steps}
            currentStep={cpStepInfo.currentStep}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          flexDirection: "column",
          margin: "20px 0 50px 0",
        }}
      >
        <Typography variant="body1" fontWeight="bold">
          Offers
        </Typography>
        <Typography variant="caption" mb={2}>
          {getSubStateLabel("acceptingOffers")}
        </Typography>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "10px",
            background: colors.grey[300],
            borderRadius: "0 5px 5px 0",
          }}
        >
          <TimelineStage
            primaryColor={"orange"}
            steps={oStepInfo.steps}
            currentStep={oStepInfo.currentStep}
          />
        </Box>
      </Box>

      {/* {workflow.offers?.map((offer, i) => {
        let completedSteps = 1;
        if (offer.buyerState === "SigningReady") completedSteps++;
        if (offer.buyerState === "SigningSentForSigning") completedSteps += 2;
        if (offer.buyerState === "SigningComplete") completedSteps += 3;
        if (offer.sellerState === "SigningComplete") completedSteps++;
        if (offer.sellerState === "SigningSentForSigning") completedSteps += 2;
        if (offer.sellerState === "SigningComplete") completedSteps += 3;

        return (
          <Box key={i}>
            <TimelineStage
              primaryColor={"teal"}
              steps={cpStepInfo.steps}
              currentStep={completedSteps}
            />
          </Box>
        );
      })} */}
    </Box>
  );
};

export default Timeline;
