import { FC } from "react";

import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { FormInputProps } from "./events";

export interface FormInputTextProps extends FormInputProps {
  multiline?: boolean;
  minRows?: string | number;
  disabled?: boolean;
}

const FormInputText: FC<FormInputTextProps> = ({
  name,
  control,
  label,
  required,
  minRows,
  multiline,
  disabled,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          value={field.value}
          key={name}
          label={label}
          size="small"
          fullWidth
          required={required}
          helperText={fieldState.error?.message}
          error={fieldState.error ? true : false}
          aria-invalid={fieldState.error ? true : false}
          autoComplete="false"
          variant="filled"
          multiline={multiline}
          minRows={minRows}
          disabled={disabled}
        />
      )}
    />
  );
};

export default FormInputText;
