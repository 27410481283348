import { Box, Typography } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useAppDispatch } from "../../app/hooks";
import { createCustomer } from "./create-subscription-slice";
import LCCard from "../../components/cards/lc-card";
import LCButton from "../../components/button";

const FeatureCard = ({
  title,
  features,
}: {
  title: string;
  features: string[];
}) => (
  <LCCard
    title={title}
    titleColor="#000"
    txtColor="#000"
    bgColor="#fff"
    sxCard={{
      width: "100%",
      minHeight: 300,
      outline: "2px solid rgba(208, 226, 255, .5)",
    }}
  >
    <Box component="ul" sx={{ margin: 0, paddingLeft: 2 }}>
      {features.map((feature, index) => (
        <Box
          key={index}
          component="li"
          sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
        >
          <CheckOutlinedIcon sx={{ color: "green", marginRight: 1 }} />
          {feature}
        </Box>
      ))}
    </Box>
  </LCCard>
);

const AccountFeatureSummary = () => {
  const dispatch = useAppDispatch();

  const handleStartSubscription = () => {
    dispatch(createCustomer());
  };

  const agentFeatures = [
    "Create workspaces",
    "Title search",
    "Client Management",
    "Property Listings",
    "Digital Contract Generation",
    "Task Automation",
    "Analytics and Reporting",
  ];
  const solicitorFeatures = [
    "Create workspaces",
    "Title search",
    "Client Management",
    "Property Listings",
    "Digital Contract Generation",
    "Task Automation",
    "Analytics and Reporting",
  ];

  return (
    <LCCard
      title={"Subscriptions"}
      titleColor="#000"
      txtColor="#000"
      bgColor="#fff"
    >
      <Box sx={{ maxWidth: 1000, margin: "0 auto" }}>
        <Typography variant="h6" mb={3}>
          You currently don't have a subscription. Would you like to create one?
        </Typography>
        <Typography variant="body1" paragraph>
          A subscription is required to create workspaces if your company is a
          real estate agency, or to participate in a workspace if your company
          is a conveyancing or solicitors' firm. It requires a credit card and
          takes less than 3 minutes to complete.
        </Typography>

        <Box sx={{ display: "flex", gap: 5, margin: "50px 0" }}>
          <FeatureCard title="Agent Features" features={agentFeatures} />
          <FeatureCard
            title="Solicitors Features"
            features={solicitorFeatures}
          />
        </Box>
        <Box sx={{ width: 200, margin: "0 auto" }}>
          <LCButton
            label="Create Account"
            color="#8F1219"
            onClick={handleStartSubscription}
          />
        </Box>
      </Box>
    </LCCard>
  );
};

export default AccountFeatureSummary;
