import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { OfferDetail, Workflow, Workspace } from "../generated";
import { OnOfferClicked } from "./events";
import OfferCard from "./offer-card";

export interface OfferTableProps {
  offers: OfferDetail[];
  workspace: Workspace;
  workflow: Workflow;
  onOfferClicked: OnOfferClicked;
  onSendToSeller: OnOfferClicked;
  onSendToBuyer: OnOfferClicked;
}

const OfferTable: FC<OfferTableProps> = ({ offers, workspace, workflow }) => {
  return (
    <Grid container>
      {offers.length > 0 ? (
        offers.map((offer, index) => (
          <Grid key={index} item lg={4} md={6} xs={12} sx={{ padding: 1 }}>
            <OfferCard
              offer={offer}
              workspace={workspace}
              workflow={workflow}
            />
          </Grid>
        ))
      ) : (
        <Grid
          item
          xs={12}
          sx={{ padding: 1, marginBottom: 5, textAlign: "center" }}
        >
          <Typography variant="h6">No offers available</Typography>
          <Typography variant="body1">
            Please create new offer to start
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default OfferTable;
