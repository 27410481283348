import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ContractStepperStatus,
  contractStepperState,
  initialise,
  moveToStep,
} from "./contract-stepper-slice";

import React, { useEffect, useState } from "react";
import { getContract, getDocuments, getPdf } from "./thunks";

import { Box, Grid } from "@mui/material";
import LCCard from "../../components/cards/lc-card";
import {
  validateContractProperty,
  validateEncumbrances,
  validateSellerDetails,
  validateSellerSolicitor,
} from "../../utils/validationUtils";
import KonveiStepper from "../../components/konvei-stepper";

const sellerSolicitorSteps = [
  "sellers",
  "agent",
  "buyers",
  "price",
  "solicitor",
  "finance",
  "buyers-solicitor",
  "property",
  "encumbrances",
  "special-conditions",
  "pool-safety",
  "safety-switches-and-alarms",
  "neighbourhood-disputes",
  "annexures",
  "review",
];

const sellerSolicitorPreparationSteps = [
  "sellers",
  "agent",
  "solicitor",
  "property",
  "encumbrances",
  "special-conditions",
  "pool-safety",
  "safety-switches-and-alarms",
  "neighbourhood-disputes",
  "annexures",
  "review",
];

const buyerSolicitorSteps = [
  "agent",
  "solicitor",
  "property",
  "buyers-solicitor",
  "encumbrances",
  "special-conditions",
  "finance",
  "pool-safety",
  "safety-switches-and-alarms",
  "neighbourhood-disputes",
  "annexures",
  "review",
];

// const sellerAgentSteps = [
//   "seller",
//   "agent",
//   "solicitor",
//   "property",
//   "encumbrances",
//   "special-conditions",
//   "pool-safety",
//   "safety-switches-and-alarms",
//   "neighbourhood-disputes",
//   "annexures",
//   "review",
// ];

const sellerAgentSteps = [
  "seller",
  "buyer-details",
  "price",
  "settlement",
  "offer-draft-view",
];

const ContractStepperPage: React.FC = () => {
  const [validationErrors, setValidationErrors] = useState<
    { message: string; step: number }[]
  >([]);

  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();

  const params = useParams();
  const wid = params["workspaceId"];
  const oid = params["offerId"];
  let offerId = 0;
  const workspaceId = Number(wid);
  if (oid) {
    offerId = Number(oid);
  }

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getContract({ workspaceId, offerId }));
    dispatch(getDocuments(workspaceId));
    //  fetchDocuments();
  }, [workspaceId]);

  useEffect(() => {
    if (state.workspaceId != workspaceId) {
      dispatch(initialise(workspaceId));
    }
    // if (state.status === ContractStepperStatus.annexureAdded) {
    //   dispatch(getDocuments(workspaceId));
    // }
  }, [state, dispatch, workspaceId]);

  function onStepChanged(step: string): void {
    dispatch(moveToStep(step));

    navigate(step);

    if (step == "Review") {
      dispatch(getPdf(workspaceId));
    }
  }

  useEffect(() => {
    if (state.step == "Review") {
      const sellerDetails = validateSellerDetails(state.sellers);
      const solicitorDetails = validateSellerSolicitor(state.sellerSolicitor);
      const contractProperty = validateContractProperty(
        state.workspace.property!
      );
      const encumbrances = validateEncumbrances(state.contract);

      const toValidate = [
        ...sellerDetails,
        ...solicitorDetails,
        ...contractProperty,
        ...encumbrances,
      ];

      const allErrors = toValidate
        .filter((error) => error !== null)
        .map((error) => ({
          message: error,
          step: parseInt(error.match(/Part (\d+)/)?.[1] ?? "0"),
        }));
      setValidationErrors(allErrors);
      console.log(validationErrors);
    }
  }, [state.step]);

  let steps = [""],
    title = "";
  switch (state.workspace.workspace?.role) {
    case "seller":
    case "sellerSolicitor":
      if (offerId === 0) {
        steps = sellerSolicitorPreparationSteps;
        title = "Contract and Disclosures";
      } else {
        steps = sellerSolicitorSteps;
        title = "Contract";
      }
      break;

    case "sellerAgent":
      steps = sellerAgentSteps;
      title = "Offer";
      break;

    case "buyer":
    case "buyerSolicitor":
      steps = buyerSolicitorSteps;
      title = "Contract and Disclosures";
      break;

    default:
      break;
  }

  return (
    <Grid item xs={12}>
      <LCCard
        title={title}
        txtColor="#000"
        titleColor="#000"
        bgColor="#fff"
        sxCard={{ overflow: "visible" }}
      >
        {state.status == ContractStepperStatus.initial ||
        state.status == ContractStepperStatus.loading ? (
          <></>
        ) : (
          <React.Fragment>
            <Box sx={{ position: "sticky", top: 68, zIndex: 1000 }}>
              <KonveiStepper
                steps={steps}
                onStepChanged={onStepChanged}
                activeStep={state.step}
                isCompressed={true}
              />
            </Box>
            <Outlet></Outlet>
          </React.Fragment>
        )}
      </LCCard>
    </Grid>
  );
};

export default ContractStepperPage;
