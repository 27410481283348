import { FC } from "react";

import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { PatternFormat } from "react-number-format";
import { FormInputProps } from "./events";

export interface FormInputPatternProps extends FormInputProps {
  format: string;
  type?: "text" | "tel" | "password" | undefined;
  disabled?: boolean;
}

const FormInputPattern: FC<FormInputPatternProps> = ({
  name,
  label,
  control,
  format,
  type,
  required,
  disabled,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <PatternFormat
          {...field}
          format={format}
          allowEmptyFormatting
          required={required}
          customInput={TextField}
          value={field.value}
          type={type}
          label={label}
          helperText={fieldState.error?.message}
          size="small"
          fullWidth
          error={fieldState.error ? true : false}
          aria-invalid={fieldState.error ? true : false}
          autoComplete="false"
          key={name}
          variant="filled"
          disabled={disabled}
        />
      )}
    />
  );
};

export default FormInputPattern;
