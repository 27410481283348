import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  getAddress,
  lookupAddress,
  getTenancyUsers,
  createWorkspace,
  searchForTitleByAddress,
} from "./thunks";
import {
  FlattenedAddress,
  PhysicalAustralianAddress,
  TeamParticipant,
  TitleSearchResult,
  User,
} from "../../generated";

export enum CreateWorkspaceStatus {
  initial,
  ready,
  pending,
  worspaceCreated,
}
interface MattersState {
  status: CreateWorkspaceStatus;
  address: string;
  matches: Array<FlattenedAddress>;
  selectedAddress?: FlattenedAddress;
  selectedTitle?: TitleSearchResult;
  users: Array<TeamParticipant>;
  newWorkspaceId?: number;
  addressSearchResult: Array<TitleSearchResult>;
  selectedAgent?: User;
  addressDetails?: PhysicalAustralianAddress;
}

const initialState: MattersState = {
  status: CreateWorkspaceStatus.initial,
  address: "",
  matches: [],
  users: [],
  addressSearchResult: [],
};

export const createWorkspaceSlice = createSlice({
  name: "createWorkspace",
  initialState,
  reducers: {
    initial: (state) => {
      state.status = CreateWorkspaceStatus.initial;
    },
    changeAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    selectedAddress: (
      state,
      action: PayloadAction<FlattenedAddress | undefined>
    ) => {
      state.selectedAddress = action.payload;
    },
    selectedTitle: (
      state,
      action: PayloadAction<TitleSearchResult | undefined>
    ) => {
      state.selectedTitle = action.payload;
    },
    selectedAgent: (state, action: PayloadAction<User | null>) => {
      if (action.payload != null) {
        state.selectedAgent = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    lookupAddressReducer(builder);
    getAddressReducer(builder);
    getUsersReducer(builder);
    createWorkspaceReducer(builder);
    searchByAddressReducer(builder);
  },
});

function getUsersReducer(builder: ActionReducerMapBuilder<MattersState>) {
  builder.addCase(getTenancyUsers.fulfilled, (state, action) => {
    state.users = action.payload;
    state.status = CreateWorkspaceStatus.ready;
  });
}

function searchByAddressReducer(
  builder: ActionReducerMapBuilder<MattersState>
) {
  builder.addCase(searchForTitleByAddress.fulfilled, (state, action) => {
    state.addressSearchResult = action.payload;
    state.status = CreateWorkspaceStatus.ready;
  });
}

function createWorkspaceReducer(
  builder: ActionReducerMapBuilder<MattersState>
) {
  builder.addCase(createWorkspace.fulfilled, (state, action) => {
    state.newWorkspaceId = action.payload;
    state.status = CreateWorkspaceStatus.worspaceCreated;
  });
  builder.addCase(createWorkspace.pending, (state) => {
    state.status = CreateWorkspaceStatus.pending;
  });
}

function lookupAddressReducer(builder: ActionReducerMapBuilder<MattersState>) {
  builder.addCase(lookupAddress.fulfilled, (state, action) => {
    state.matches = action.payload;
    state.status = CreateWorkspaceStatus.ready;
  });
}
function getAddressReducer(builder: ActionReducerMapBuilder<MattersState>) {
  builder.addCase(getAddress.fulfilled, (state, action) => {
    state.addressDetails = action.payload;
    state.status = CreateWorkspaceStatus.ready;
  });
}

export const {
  changeAddress,
  initial,
  selectedAddress,
  selectedTitle,
  selectedAgent,
} = createWorkspaceSlice.actions;
export default createWorkspaceSlice.reducer;
export const createWorkspaceState = (state: RootState) => state.createWorkspace;
