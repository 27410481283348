import Grid from "@mui/material/Grid";
import React from "react";
import { Participant } from "../generated";
import { Control } from "react-hook-form";
import FormInputPattern from "./form-input-pattern";
import FormInputText from "./form-input-text";

export interface ContactDetailsProps {
  control: Control<Participant>;
  disabled?: boolean;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({
  control,
  disabled,
}) => {
  return (
    <React.Fragment>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormInputPattern
              name="phone"
              format="##########"
              label="Phone"
              control={control}
              required
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInputText
              name="email"
              label="Email"
              control={control}
              key="email"
              required
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ContactDetails;
