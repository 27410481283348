import Avatar from "@mui/material/Avatar";
import { FC, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { Box, Divider, Typography } from "@mui/material";

export interface AccountAvatarProps {
  email: string;
  firstName?: string;
  lastName?: string;
}

const AccountAvatar: FC<AccountAvatarProps> = ({
  firstName,
}) => {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenSettings = () => {
    navigate("/settings");
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        sx={{
          padding: {
            sm: "8px",
            xs: "5px 8px",
          },
        }}
      >
        <Avatar
          sx={{
            width: { sm: 34, xs: 29 },
            height: { sm: 34, xs: 29 },
            marginRight: { sm: 2, xs: 1 },
            bgcolor: "#8F1219",
            fontSize: 11,
          }}
        ></Avatar>
        <SettingsOutlinedIcon
          fontSize={"medium"}
          sx={{
            width: { sm: 30, xs: 25 },
            height: { sm: 30, xs: 25 },
            color: "#2A629A",
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            width: "300px",
            maxWidth: "100%",
            borderRadius: 3,
          },
        }}
      >
        <Box
          sx={{
            padding: "25px 15px",
          }}
        >
          <Typography variant="h6" align="center">
            Hello, {firstName}
          </Typography>
          <Divider sx={{ margin: "30px 10px" }} />

          <MenuItem onClick={handleOpenSettings}>
            <SettingsOutlinedIcon sx={{ marginRight: 2 }} />
            Settings
          </MenuItem>
          <MenuItem
            onClick={() => {
              auth.signoutRedirect();
            }}
          >
            <ExitToAppOutlinedIcon sx={{ marginRight: 2 }} /> Signout
          </MenuItem>
        </Box>
      </Menu>
    </div>
  );
};

export default AccountAvatar;
