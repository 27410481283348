import { Grid, Typography, useTheme } from "@mui/material";
import { FC, useEffect } from "react";
import ParticipantNameForm from "../../../components/participant-name-form";
import ContactDetails from "../../../components/contact-details";
import AddressForm from "../../../components/address-form";
import { Participant1 } from "../../../generated";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SettingsStatus, settingsState } from "../settings-slice";
import { getMyDetails, updateMyDetails } from "../thunks";
import AutoSave from "../../../components/auto-save";
import LCCard from "../../../components/cards/lc-card";

const MyDetails: FC = () => {
  const theme = useTheme();
  const state = useAppSelector(settingsState);
  const dispatch = useAppDispatch();

  const formMethods = useForm<Participant1>({
    defaultValues: state.myDetails,
  });
  const { handleSubmit, control } = formMethods;

  const handleMyDetailsFormSubmit = (data: Participant1) => {
    dispatch(updateMyDetails(data));
  };
  useEffect(() => {
    if (state.myDetailsStatus == SettingsStatus.initial) {
      dispatch(getMyDetails());
    }
  }, [dispatch, state.myDetailsStatus, state.myDetails]);

  return (
    <LCCard
      title={"My Details"}
      titleColor="#000"
      txtColor="#000"
      bgColor="#fff"
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleMyDetailsFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <ParticipantNameForm
                control={control}
                captureMiddleName={true}
                captureGst={true}
                participantType={"individual"}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Contact
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ContactDetails control={control} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Address
              </Typography>
            </Grid>
            <AddressForm
              control={control}
              address={state.myDetails}
              setValue={(s) => {
                console.log(s);
              }}
            />
          </Grid>
          <AutoSave
            defaultValues={state.myDetails}
            onSubmit={handleMyDetailsFormSubmit}
          />
        </form>
      </FormProvider>
    </LCCard>
  );
};

export default MyDetails;
