import { FC } from "react";
import Page from "../../components/page";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { withAuthenticationRequired } from "react-oidc-context";
import RedirectToLogin from "../../components/redirect-to-login";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LCCard from "../../components/cards/lc-card";

const SettingsPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  function handleMyDetailsClick(): void {
    navigate("my-details");
  }

  const isActive = (path: string) => location.pathname.endsWith(path);

  return (
    <Page title="Settings">
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <LCCard
            title="Settings"
            titleColor="#000"
            txtColor="#000"
            bgColor="#fff"
            sxCard={{ minHeight: 300 }}
          >
            <List>
              <ListItem key="settings" disablePadding>
                <ListItemButton
                  onClick={handleMyDetailsClick}
                  sx={{
                    backgroundColor: isActive("my-details")
                      ? "rgba(0, 0, 0, 0.04)"
                      : "inherit",
                  }}
                >
                  <ListItemIcon>
                    <ManageAccountsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Details" />
                </ListItemButton>
              </ListItem>
              <ListItem key="licencee" disablePadding>
                <ListItemButton
                  onClick={() => navigate("account")}
                  sx={{
                    backgroundColor: isActive("account")
                      ? "rgba(0, 0, 0, 0.04)"
                      : "inherit",
                  }}
                >
                  <ListItemIcon>
                    <AccountCircleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Account" />
                </ListItemButton>
              </ListItem>
            </List>
          </LCCard>
        </Grid>
        <Grid item xs={9}>
          <Outlet />
        </Grid>
      </Grid>
    </Page>
  );
};

export default withAuthenticationRequired(SettingsPage, {
  OnRedirecting: () => <RedirectToLogin />,
  signinRedirectArgs: { redirect_uri: window.location.href },
});
