import React, { useEffect } from "react";
import {
  agentAppointmentState,
  AgentAppointmentStatus,
  initialise,
  moveToStep,
} from "./agent-appointment-slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getAgentAppointment } from "./thunks";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import { Box } from "@mui/material";
import KonveiStepper from "../../components/konvei-stepper";

const AgentAppointmentPage: React.FC = () => {
  const state = useAppSelector(agentAppointmentState);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  //const [setErrors] = useState<{ message: string; step: number }[]>([]);
  const steps = [
    "client",
    "agent",
    "property",
    "appointment",
    "sale",
    "commission",
    "authorisation",
    "review",
  ];

  // useEffect(() => {
  //   if (steps.indexOf(state.step) == 7) {
  //     const clientErrors = validateClientDetails(state.clients, 0);
  //     const licenceeErrors = validateLicenceeDetails(
  //       state.licencee,
  //       state.licenceeAgent,
  //       1
  //     );
  //     const propertyErrors = validateProperty(state.property, 2);
  //     const appointmentErrors = validateAppointment(state.agentAppointment, 3);
  //     const propertySaleErrors = validatePropertySale(
  //       state.agentAppointment,
  //       4
  //     );
  //     // const commissionErrors = validateCommission(state.fees, state.benefits);
  //     const authorisationErrors = validateAuthorisation(
  //       state.agentAppointment,
  //       6
  //     );
  //     const toValidate = [
  //       ...clientErrors,
  //       ...licenceeErrors,
  //       ...propertyErrors,
  //       ...appointmentErrors,
  //       ...propertySaleErrors,
  //       // ...commissionErrors,
  //       ...authorisationErrors,
  //     ];

  //     const allErrors = toValidate
  //       .filter((error) => error !== null)
  //       .map((error) => ({
  //         message: error,
  //         step: parseInt(error.match(/Part (\d+)/)?.[1] ?? "0"),
  //       }));
  //    // setErrors(allErrors);
  //   }
  // }, [state.step]);

  const workspaceId = parseInt(params["workspaceId"] as string);
  const s = state.status;
  useEffect(() => {
    if (workspaceId != state.workspaceId) {
      dispatch(initialise(workspaceId));
    }
    switch (s) {
      case AgentAppointmentStatus.initial:
        dispatch(getAgentAppointment(workspaceId));
        break;

      default:
        break;
    }

    // if (workspaceId) {
    //   const pathSegments = location.pathname.split("/").filter(Boolean);
    //   const lastSegment = pathSegments[pathSegments.length - 1] || "/";
    //   dispatch(moveToStep(lastSegment));
    //   dispatch(getAgentAppointment(workspaceId));
    // }
  }, [workspaceId, dispatch]);

  function onStepChanged(step: string): void {
    dispatch(moveToStep(step));

    navigate(step);
  }

  return (
    <Card
      sx={{
        boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        overflow: "unset",
        background: "#fff",
      }}
    >
      <CardHeader title="Agent Appointment" />
      <CardContent>
        {steps.indexOf(state.step) < 9 ? (
          <Box
            sx={{
              position: "sticky",
              background: "#fff",
              paddingBottom: "1px",
              top: 0,
              zIndex: 1000,
            }}
          >
            <KonveiStepper
              steps={steps}
              activeStep={state.step}
              onStepChanged={onStepChanged}
              isCompressed={true}
            />
          </Box>
        ) : (
          <></>
        )}

        <Outlet></Outlet>
      </CardContent>
    </Card>
  );
};

export default AgentAppointmentPage;
