import { FC, useState } from "react";
import { OfferDetail, Workflow, Workspace } from "../generated";
import { Box, Chip, Button, Menu, MenuItem, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import {
  participantName,
  cleanAndCapitalize,
  australianCurrencyFormatter,
  currencyFromBaseUnit,
  formatDateAndTime,
  getParticipantsByRole,
} from "../helpers/helpers";
import LCCard from "./cards/lc-card";
import { useNavigate } from "react-router-dom";

import {
  sendToBuyerForSigning,
  sendToSellerForSigning,
  shareWithSeller,
} from "../features/workspace/thunks";

import { cancelOffer, inviteBuyers } from "../features/offer-stepper/thunks";
import { useAppDispatch } from "../app/hooks";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

export interface OfferTableProps {
  offer: OfferDetail;
  workspace: Workspace;
  workflow: Workflow;
  txtColor?: string;
  bgColor?: string;
  boxShadow?: boolean;
}

const OfferCard: FC<OfferTableProps> = ({
  offer,
  workspace,
  txtColor = "#000",
  bgColor = "#f5f9fa",
  boxShadow = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSendToBuyer(offerId: number): void {
    dispatch(
      sendToBuyerForSigning({
        workspaceId: workspace.id,
        offerId: offerId,
      })
    );
  }

  function handleInviteBuyers(offerId: number): void {
    dispatch(
      inviteBuyers({
        workspaceId: workspace.id,
        offerId: offerId,
      })
    );
  }

  function handleSendToSellers(offerId: number): void {
    dispatch(
      sendToSellerForSigning({
        workspaceId: workspace.id,
        offerId: offerId,
      })
    );
  }

  function handleShareWithSeller(offerId: number): void {
    dispatch(
      shareWithSeller({
        workspaceId: workspace.id,
        offerId: offerId,
      })
    );
  }
  function handleCancelOffer(offerId: number): void {
    dispatch(
      cancelOffer({
        workspaceId: workspace.id,
        offerId: offerId,
      })
    );
  }

  const handleMenuItemClick = (action: any, offerId: number) => {
    if (action === "Edit") {
      navigate(`${offerId}`);
    } else if (action === "Draft") {
      navigate(`${offerId}/draft`);
    } else if (action === "inviteBuyers") {
      handleInviteBuyers(offerId);
    } else if (action === "sendToSeller") {
      handleSendToSellers(offerId);
    } else if (action === "shareWithSeller") {
      handleShareWithSeller(offerId);
    } else if (action === "sendToBuyer") {
      handleSendToBuyer(offerId);
    } else if (action === "cancelOffer") {
      handleCancelOffer(offerId);
    }
    handleClose();
  };
  let buyers = getParticipantsByRole(offer.participants, "buyer");

  return (
    <LCCard
      title={
        <Box sx={{ display: "flex", gap: 2 }}>
          {buyers.map((p, i) => {
            if (i == buyers.length - 1) {
              return participantName(p);
            } else {
              return participantName(p) + ", ";
            }
          })}
          {offer.offer.status && (
            <Chip
              label={cleanAndCapitalize(offer.offer.status)}
              color={offer.offer.status === "not-started" ? "info" : "primary"}
            />
          )}
        </Box>
      }
      buttons={
        workspace.role == "sellerAgent" ? (
          <Button color="inherit">
            <MoreHorizIcon
              onClick={(e) => {
                handleClick(e);
              }}
              sx={{ zIndex: 1 }}
            />
          </Button>
        ) : (
          <Box
            sx={{ width: "40px", zIndex: 9, position: "relative" }}
            onClick={() => handleMenuItemClick("Edit", offer.offer.id!)}
          >
            <EditNoteOutlinedIcon />
          </Box>
        )
      }
      txtColor={txtColor}
      titleColor="#000"
      bgColor={bgColor}
      boxShadow={boxShadow}
    >
      {workspace.role == "sellerAgent" ? (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={() => handleMenuItemClick("Edit", offer.offer.id!)}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemClick("Draft", offer.offer.id!)}
          >
            Draft
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemClick("inviteBuyers", offer.offer.id!)}
          >
            Invite Buyers
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemClick("sendToBuyer", offer.offer.id!)}
          >
            Send to Buyer
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemClick("sendToSeller", offer.offer.id!)}
          >
            Send to Seller
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleMenuItemClick("shareWithSeller", offer.offer.id!)
            }
          >
            Share to Seller
          </MenuItem>

          <MenuItem
            onClick={() => handleMenuItemClick("cancelOffer", offer.offer.id!)}
          >
            Cancel Offer
          </MenuItem>
        </Menu>
      ) : (
        <></>
      )}

      <Typography gutterBottom>
        <strong>Purchase price: </strong>
        {australianCurrencyFormatter.format(
          currencyFromBaseUnit(offer.offer.purchasePrice)!
        )}
      </Typography>
      <Typography gutterBottom>
        <strong>Settlement Date: </strong>
        {formatDateAndTime(offer.offer.settlementDate, false)}
      </Typography>
      <Typography gutterBottom>
        <strong>Deposit: </strong>
        {offer.offer.depositInitial == null
          ? "$0.00"
          : australianCurrencyFormatter.format(
              currencyFromBaseUnit(offer.offer.depositInitial)!
            )}
      </Typography>

      <Typography gutterBottom>
        <strong>Finance: </strong>
        {offer.offer.financeDate ? "yes" : "no"}
      </Typography>

      <Typography gutterBottom>
        <strong>Building & Pest: </strong>
        {offer.offer.buildingAndPestInspection ? "yes" : "no"}
      </Typography>

      <Typography gutterBottom>
        <strong>Special Conditions: </strong>
        {offer.offer.specialConditions ? "yes" : "no"}
      </Typography>
    </LCCard>
  );
};

export default OfferCard;
