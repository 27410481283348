import Grid from "@mui/material/Grid";
import React, { Fragment } from "react";
import { Participant } from "../../../generated";
import { OnParticipantChanged } from "../../../components/events";
import BuyerDetail from "./buyer-detail";
import LCButton from "../../../components/button";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";
import { addBuyer, deleteBuyer, saveContractParticipant } from "../thunks";

export type OnAddBuyer = () => void;

export interface BuyersDetailsProps {
  buyers: Array<Participant>;
  onBuyersDetailsChanged: OnParticipantChanged;
  onAddBuyer: OnAddBuyer;
  onBuyerRemoved: OnParticipantChanged;
}
const BuyersDetails: React.FC = () => {
  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const wid = params["workspaceId"];
  const oid = params["offerId"];
  let offerId = 0;
  const workspaceId = Number(wid);
  if (oid) {
    offerId = Number(oid);
  }
  function handleOnAddBuyer(): void {
    dispatch(addBuyer({ workspaceId, offerId }));
  }

  function handleOnBuyerRemoved(participant: Participant): void {
    dispatch(
      deleteBuyer({
        contractId: workspaceId,
        offerId: offerId,
        participantId: participant.id!,
      })
    );
  }

  function handleOnBuyersDetailsChanged(participant: Participant): void {
    dispatch(
      saveContractParticipant({
        participant: participant,
        workspaceId: workspaceId,
        offerId: offerId,
      })
    );
  }

  return (
    <FormWrapper title="Buyer's Details">
      <Fragment>
        {state.buyers.map((buyer, index) => (
          <BuyerDetail
            key={buyer.id}
            buyer={buyer}
            onChanged={handleOnBuyersDetailsChanged}
            onDelete={handleOnBuyerRemoved}
            index={index}
          />
        ))}

        <Grid container item spacing={2} justifyContent={"center"}>
          <Grid item xs={5} sm={3}>
            <LCButton
              label="add another buyer"
              color="#000"
              onClick={handleOnAddBuyer}
            />
          </Grid>
        </Grid>
      </Fragment>
    </FormWrapper>
  );
};

export default BuyersDetails;
