import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { assertUser } from "./thunks";
import { RootState } from "../../app/store";

export enum AuthCallbackStatus {
  initial,
  asserting,
  ready,
}

interface AuthCallbackState {
  status: AuthCallbackStatus;
  isNewUser?: boolean;
}
const initialState: AuthCallbackState = {
  status: AuthCallbackStatus.initial,
};

export const authCallbackSlice = createSlice({
  name: "authCallback",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(assertUser.pending, (state) => {
      state.status = AuthCallbackStatus.asserting;
    });
    builder.addCase(
      assertUser.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.isNewUser = action.payload;
        state.status = AuthCallbackStatus.ready;
      }
    );
  },
});

export default authCallbackSlice.reducer;
export const authCallbackState = (state: RootState) => state.authCallback;