import { FC } from "react";
import { Box, colors } from "@mui/material";
import LCLinearProgressBar from "../../components/linear-progress/lc-linear-progress";

export enum TimelineStageStatus {
  NotStarted = "not-started",
  Inprogress = "in-progress",
  Completed = "completed",
}

export interface TimelineStageProps {
  primaryColor: string;
  steps: number;
  currentStep: number;
  alert?: boolean;
}

const TimelineStage: FC<TimelineStageProps> = ({
  steps,
  currentStep,
  // primaryColor,
  alert,
}) => {
  let status: TimelineStageStatus;

  // let backgroundColor: string;
  if (currentStep === 0) {
    // backgroundColor = colors.grey[400];
    status = TimelineStageStatus.NotStarted;
  } else if (currentStep === steps) {
    // backgroundColor = colors.green[400];
    status = TimelineStageStatus.Completed;
  } else {
    // backgroundColor = primaryColor;
    status = TimelineStageStatus.Inprogress;
  }

  const progress = (currentStep / steps) * 100;

  return (
    <Box sx={{ position: "relative", width: "100%", height: "10px" }}>
      {status === TimelineStageStatus.Inprogress ? (
        <LCLinearProgressBar sx={{ height: "10px" }} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "10px",
            backgroundColor:
              status == TimelineStageStatus.Completed
                ? colors.green[400]
                : colors.grey[300],
            borderRadius: "5px",
          }}
        />
      )}

      {/* Progress Track */}
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: `${progress}%`,
          height: "10px",
          backgroundColor:
            status === TimelineStageStatus.Inprogress
              ? colors.green[400]
              : "transparent",
          borderRadius: "5px",
        }}
      />

      {/* Progress Indicator */}
      {status === TimelineStageStatus.Inprogress && (
        <Box
          sx={{
            position: "absolute",
            left: `${progress}%`,
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "10px",
            height: "10px",
            backgroundColor: alert ? colors.red[600] : "white",
            borderRadius: "50%",
          }}
        />
      )}

      {/* Start and End Markers */}
      {/* <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "2px",
          height: "10px",
          backgroundColor: colors.green[800],
        }}
      />
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          width: "2px",
          height: "10px",
          backgroundColor: colors.green[800],
        }}
      /> */}
    </Box>
  );
};

export default TimelineStage;
