import { FC, Fragment, useEffect } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormGroup, Grid, Typography, useTheme } from "@mui/material";
import AddressForm from "../../../components/address-form";
import ParticipantNameForm from "../../../components/participant-name-form";
import FormInputCheckbox from "../../../components/form-input-checkbox";
import FormInputText from "../../../components/form-input-text";
import ContactDetails from "../../../components/contact-details";
import FormInputDatePicker from "../../../components/form-input-date-picker";
import { AgentAppointment, Participant } from "../../../generated";
import {
  OnAgentAppointmentChanged,
  OnParticipantChanged,
} from "../../../components/events";
import { participantSchema } from "./schemas";
import FormWrapper from "./form-wrapper";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import sellerAgent from "../../contract-stepper/components/seller-agent";
import { agentAppointmentState } from "../agent-appointment-slice";
import { updateAgentAppointment } from "../thunks";
import { saveContractParticipant } from "../../contract-stepper/thunks";
import AutoSave from "../../../components/auto-save";

export interface LicenceeDetailsProps {
  agentAppointment: AgentAppointment;
  licencee: Participant;
  sellerAgent: Participant;
  onLicenceeChanged: OnParticipantChanged;
  onLicenceeAgentChanged: OnParticipantChanged;
  onAgentAppointmentChanged: OnAgentAppointmentChanged;
}

const LicenceeDetails: FC = () => {
  const state = useAppSelector(agentAppointmentState);
  const params = useParams();
  const dispatch = useAppDispatch();
  const workspaceId = Number(params.workspaceId);
  const theme = useTheme();

  // const licenceeSchema = yup
  //   .object({
  //     organisationName: yup.string().required(),
  //     abn: yup
  //       .string()
  //       .matches(/^(\d *?){11}$/, "must have 11 digits")
  //       .required(),
  //     acn: yup
  //       .string()
  //       .matches(/^(\d *?){9}$/, "must have 9 digits")
  //       .required(),
  //   })
  //   .required();

  const agentAppointmentFormMethods = useForm<AgentAppointment>({
    defaultValues: state.agentAppointment,
    //resolver: yupResolver(agentAppointmentSchema),
  });
  const {
    handleSubmit: agentAppointmentHandleSubmit,
    control: agentAppointmentControl,
    reset: agentAppointmentReset,
  } = agentAppointmentFormMethods;

  const licenceeAgentFormMethods = useForm<Participant>({
    defaultValues: state.licenceeAgent,
    resolver: yupResolver(participantSchema),
  });

  const {
    handleSubmit: sellerAgentHandleSubmit,
    control: sellerAgentControl,
    reset: sellerAgentReset,
    setValue: sellerAgentSetValue,
    trigger: sellerAgentTrigger,
  } = licenceeAgentFormMethods;

  useEffect(() => {
    agentAppointmentReset(state.agentAppointment);
  }, [state.agentAppointment]);

  useEffect(() => {
    sellerAgentReset(state.licenceeAgent);
    sellerAgentTrigger();
  }, [sellerAgent]);

  const onSellerAgentSubmit: SubmitHandler<Participant> = (data) => {
    dispatch(
      saveContractParticipant({ participant: data, workspaceId: workspaceId })
    );
  };

  const notes = [
    {
      title: "Licensee Type:",
      items: [
        "More than one box may be ticked if appropriate.",
        "Annexures detailing conjuncting agents may be attached if required.",
      ],
    },
    {
      title: "Licensee Name:",
      items: [
        "Where a corporation licensee is to be appointed, state the corporation’s name and licence number.",
        "Where a sole trader is to be appointed, state the individual’s name and licence number.",
      ],
    },
  ];

  const onSubmitAgentAppointment: SubmitHandler<AgentAppointment> = (data) => {
    dispatch(
      updateAgentAppointment({
        agentAppointment: data,
        workspaceId: workspaceId,
      })
    );
  };

  return (
    <FormWrapper title="Licencee Details" notes={notes}>
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Licencee Type</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormProvider {...agentAppointmentFormMethods}>
                <form
                  onSubmit={agentAppointmentHandleSubmit(
                    onSubmitAgentAppointment
                  )}
                >
                  <FormGroup>
                    <Box sx={{ display: "flex" }}>
                      <FormInputCheckbox
                        control={agentAppointmentControl}
                        name="isRealEstateAgent"
                        label="Real estate agent"
                      />
                      <FormInputCheckbox
                        control={agentAppointmentControl}
                        name="isLettingAgent"
                        label="Resident Letting Agent"
                      />
                      <FormInputCheckbox
                        control={agentAppointmentControl}
                        name="isPropertyAuctioneer"
                        label="Property Auctioneer"
                      />
                    </Box>
                  </FormGroup>
                  <AutoSave
                    defaultValues={state.agentAppointment}
                    onSubmit={onSubmitAgentAppointment}
                  ></AutoSave>
                </form>
              </FormProvider>
            </Grid>
            <FormProvider {...licenceeAgentFormMethods}>
              <form onSubmit={sellerAgentHandleSubmit(onSellerAgentSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant="h6"
                      color={theme.palette.text.secondary}
                    >
                      Licencee
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Agent Name</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <ParticipantNameForm
                      control={sellerAgentControl}
                      participantType="individual"
                      captureMiddleName={false}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Licencee</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      control={sellerAgentControl}
                      name="tradingName"
                      label="Trading name"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ParticipantNameForm
                      control={sellerAgentControl}
                      participantType={state.licenceeAgent.participantType!}
                      captureMiddleName={false}
                      organisationLabel="Licencee Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormInputText
                      control={sellerAgentControl}
                      label="Licence Number"
                      name="licenceeNumber"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormInputDatePicker
                      control={sellerAgentControl}
                      name={"licenceeExpiryDate"}
                      label={"Licence Expiry"}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant="h6"
                      color={theme.palette.text.secondary}
                    >
                      Contact details
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ContactDetails control={sellerAgentControl} />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant="h6"
                      color={theme.palette.text.secondary}
                    >
                      Address
                    </Typography>
                  </Grid>
                  <AddressForm
                    control={sellerAgentControl}
                    setValue={sellerAgentSetValue}
                    address={{ ...state.licenceeAgent }}
                  />
                  <AutoSave
                    defaultValues={state.licenceeAgent}
                    onSubmit={onSellerAgentSubmit}
                  />
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </Fragment>
      </Box>
    </FormWrapper>
  );
};

export default LicenceeDetails;
