import { Params } from "react-router-dom";

export interface GetParamsResult {
  workspaceId: number;
  offerId?: number;
}
export const getParams = (params: Params): GetParamsResult => {
  const wid = params["workspaceId"];
  const oid = params["offerId"];
  let offerId = undefined;
  const workspaceId = Number(wid);
  if (oid) {
    offerId = Number(oid);
  }
  return {
    workspaceId,
    offerId,
  };
};
