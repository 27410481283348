import { Box, Typography } from "@mui/material";
import LCCard from "../../../components/cards/lc-card";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import LCButton from "../../../components/button";

const Account = () => {
  function handleCreateAccount(): void {
    throw new Error("Function not implemented.");
  }

  const FeatureCard = ({
    title,
    features,
  }: {
    title: string;
    features: string[];
  }) => (
    <LCCard
      title={title}
      titleColor="#000"
      txtColor="#000"
      bgColor="#fff"
      sxCard={{
        width: "100%",
        minHeight: 300,
        outline: "2px solid rgba(208, 226, 255, .5)",
      }}
    >
      <Box component="ul" sx={{ margin: 0, paddingLeft: 2 }}>
        {features.map((feature, index) => (
          <Box
            key={index}
            component="li"
            sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
          >
            <CheckOutlinedIcon sx={{ color: "green", marginRight: 1 }} />
            {feature}
          </Box>
        ))}
      </Box>
    </LCCard>
  );

  const agentFeatures = [
    "Create workspaces",
    "Title search",
    "Client Management",
    "Property Listings",
    "Digital Contract Generation",
    "Task Automation",
    "Analytics and Reporting",
  ];
  const solicitorFeatures = [
    "Create workspaces",
    "Title search",
    "Client Management",
    "Property Listings",
    "Digital Contract Generation",
    "Task Automation",
    "Analytics and Reporting",
  ];

  return (
    <LCCard title={"Account"} titleColor="#000" txtColor="#000" bgColor="#fff">
      <Box sx={{ maxWidth: 1000, margin: "0 auto" }}>
        <Typography variant="h6" mb={3}>
          You do not yet have an account. Would you like to create one?
        </Typography>
        <Typography variant="body1" paragraph>
          An account is required to create workspaces if your company is a real
          estate agency, or participate in a workspace if your company is a
          conveyancer or solicitors' firm. It requires a credit card and takes
          less than 3 minutes to complete.
        </Typography>

        <Box sx={{ display: "flex", gap: 5, margin: "50px 0" }}>
          <FeatureCard title="Agent Features" features={agentFeatures} />
          <FeatureCard
            title="Solicitors Features"
            features={solicitorFeatures}
          />
        </Box>
        <Box sx={{ width: 200, margin: "0 auto" }}>
          <LCButton
            label="Create Account"
            onClick={handleCreateAccount}
            color="#8F1219"
          />
        </Box>
      </Box>
    </LCCard>
  );
};

export default Account;
