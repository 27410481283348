import Grid from "@mui/material/Grid";
import { FC, Fragment, useEffect } from "react";
import PDFViewer from "../../../components/pdf-viewer";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import LCCard from "../../../components/cards/lc-card";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  ContractPreviewStatus,
  contractStepperState,
} from "../contract-stepper-slice";
import { completeContractDrafting, getPdf } from "../thunks";
import LCButton from "../../../components/button";
import { workspaceState } from "../../workspace/workspace-slice";

const Review: FC = () => {
  const state = useAppSelector(contractStepperState);
  const workspace = useAppSelector(workspaceState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);

  function handleComplete(): void {
    dispatch(completeContractDrafting(workspaceId));
  }

  useEffect(() => {
    if (state.contractPreviewStatus == ContractPreviewStatus.initial) {
      dispatch(getPdf(workspaceId));
    }
  }, [state.status]);

  return (
    <Fragment>
      {state.contractPreviewStatus == ContractPreviewStatus.loading ? (
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress size={100}></CircularProgress>
        </Grid>
      ) : null}
      {state.contractPreviewStatus == ContractPreviewStatus.ready ? (
        <Fragment>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
          >
            {workspace.workflow?.subState ==
            "contractPreparationPreparing" ? (
              <Grid item xs={12} sm={6}>
                <LCCard
                  title="Complete Preparation"
                  txtColor="#000"
                  titleColor="#000"
                  bgColor="#fff"
                  sxCard={{
                    width: "100%",
                    minHeight: 200,
                    outline: "2px solid rgba(208, 226, 255, .5)",
                  }}
                >
                  <Typography mb={2}>
                    When you have completed preparing the contract and contract
                    disclosures. Notify the workspace by completing this task.
                    Offers to buyers cannot be sent until you finish this
                    activity.
                  </Typography>
                  <Box sx={{ width: "90px", margin: "0 auto" }}>
                    <LCButton
                      label="Complete"
                      onClick={handleComplete}
                      size="small"
                      color="green"
                    />
                  </Box>
                </LCCard>
              </Grid>
            ) : workspace.workflow?.subState == "acceptingOffers" ? (
              <Grid item xs={12} sm={6}>
                <LCCard
                  title="Confirm Agreement"
                  txtColor="#000"
                  titleColor="#000"
                  bgColor="#fff"
                  sxCard={{
                    width: "100%",
                    minHeight: 200,
                    outline: "2px solid rgba(208, 226, 255, .5)",
                  }}
                >
                  <Typography mb={2}>
                    Both you and the seller have reviewed and edited the
                    contract and disclosures. Please ensure all changes are
                    correct and that you agree with the final terms before
                    proceeding.
                  </Typography>

                  <Box sx={{ width: "80px", margin: "0 auto" }}>
                    <LCButton label="Agree" size="small" color="green" />
                  </Box>
                </LCCard>
              </Grid>
            ) : null}

            <Grid item>
              <PDFViewer url={state.url} />
            </Grid>
          </Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default Review;
