import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  contractsApi,
  offersApi,
  participantsApi,
  propertyApi,
  teamsApi,
  workspacesApi,
} from "../../api";
import {
  Annexure,
  Contract,
  Participant,
  Participants,
  Property,
  PutDocumentDocumentTypeEnum,
  WorkspaceSummary,
} from "../../generated";
import { authority, client_id, konvei_api } from "../../constants";
import { User } from "oidc-client-ts";
import axios, { AxiosResponse } from "axios";

export interface ContractResponse {
  contract: Contract;
  workspace: WorkspaceSummary;
  participants: Array<Participant>;
  annexures: Array<Annexure>;
}

export interface AnnexuresResponse {
  annexures: Array<Annexure>;
}

export interface ContractArgs {
  workspaceId: number;
  offerId?: number;
}
export const getContract = createAsyncThunk<ContractResponse, ContractArgs>(
  "contractStepper/getContract",
  async ({ workspaceId, offerId }) => {
    const resp: ContractResponse = {
      annexures: [],
      contract: {
        id: 0,
        hasEncumbrances: false,
        hasPool: false,
        hasPoolCertificate: false,
        hasSafetySwitches: false,
        hasSmokeAlarms: false,
        hasNeighbourhoodDisputes: false,
        hasTenant: false,
        status: "",
        hasSellerSolicitor: false,
      },
      participants: [],
      workspace: {},
    };
    const workspacePromise = workspacesApi.getWorkspace(workspaceId);

    let contractPromise: Promise<AxiosResponse<Contract>>;
    let offerParticipants: AxiosResponse<Participants>;

    if (offerId) {
      contractPromise = offersApi.getOffer(workspaceId, offerId);
    } else {
      contractPromise = contractsApi.getContract(workspaceId);
    }

    if (offerId) {
      offerParticipants = await offersApi.getOfferParticipants(
        workspaceId,
        offerId
      );
      offerParticipants.data.items?.forEach((p) => {
        resp.participants.push(p as Participant);
      });
    }
    const documentsPromise = contractsApi.getContractAnnexures(workspaceId);

    const contractParticipantResponse =
      participantsApi.getParticipants(workspaceId);

    const [workspace, contract, participants, annexures] = await Promise.all([
      workspacePromise,
      contractPromise,
      contractParticipantResponse,
      documentsPromise,
    ]);

    resp.workspace = workspace.data;
    resp.contract = contract.data;
    participants.data.items?.forEach((p) => {
      resp.participants.push(p);
    });
    resp.annexures = annexures.data.items!;

    return resp;
  }
);

export const getDocuments = createAsyncThunk<AnnexuresResponse, number>(
  "contractStepper/getDocuments",
  async (workspaceId) => {
    const documents = await contractsApi.getContractAnnexures(workspaceId);

    const resp: AnnexuresResponse = {
      annexures: documents.data.items,
    };

    return resp;
  }
);

export const addBuyer = createAsyncThunk<number, ContractArgs>(
  "contractStepper/addBuyer",
  async (args) => {
    const buyer: Participant = {
      role: "buyer",
    };
    const response = await offersApi.postOfferParticipant(
      args.workspaceId,
      args.offerId!,
      buyer
    );
    buyer.id = response.data.id;

    return response.data.id!;
  }
);

export interface CreateContractResponse {
  contract: Contract;
  seller: Participant;
  solicitor: Participant;
  agent: Participant;
}
export const createContract = createAsyncThunk<CreateContractResponse, number>(
  "contractStepper/createContract",
  async () => {
    const contract: Contract = {
      id: 1,
      status: "",
      hasEncumbrances: false,
      hasNeighbourhoodDisputes: false,
      hasPool: false,
      hasPoolCertificate: false,
      hasSafetySwitches: false,
      hasSellerSolicitor: false,
      hasSmokeAlarms: false,
      hasTenant: false,
    };

    const seller: Participant = {
      id: 0,
      role: "seller",
    };
    const sellerSolicitor: Participant = {
      id: 0,
      role: "sellerSolicitor",
    };

    const agent: Participant = {
      id: 0,
      role: "sellerAgent",
    };
    // const t1 = participantsApi.postParticipant(workspaceId, seller);

    // const t3 = participantsApi.postParticipant(workspaceId, agent);
    // const [sellerResp, sellerSolResp, agentResp] = await Promise.all([
    //   t1,
    //   t2,
    //   t3,
    // ]);

    const response: CreateContractResponse = {
      contract: contract,
      seller: seller,
      solicitor: sellerSolicitor,
      agent: agent,
    };
    return response;
  }
);

export interface SaveContractArgs extends ContractArgs {
  contract: Contract;
}
export const saveContract = createAsyncThunk<Contract, SaveContractArgs>(
  "contractStepper/saveContract",
  async ({ contract, workspaceId, offerId }) => {
    if (offerId) {
      await offersApi.putOffer(workspaceId, offerId, contract);
    } else {
      await contractsApi.putContract(workspaceId, contract);
    }
    return contract;
  }
);

export interface SavePropertyArgs {
  workspaceId: number;
  property: Property;
}

export const saveProperty = createAsyncThunk<void, SavePropertyArgs>(
  "contractStepper/saveProperty",
  async ({ workspaceId, property }) => {
    await propertyApi.putProperty(workspaceId, property);
  }
);

export interface UpdateContractParticipantArgs {
  workspaceId: number;
  offerId?: number;
  participant: Participant;
}
export const saveContractParticipant = createAsyncThunk<
  void,
  UpdateContractParticipantArgs
>(
  "contractStepper/saveContractParticipant",
  async ({ workspaceId, participant, offerId }) => {
    switch (participant.role) {
      case "seller":
      case "sellerAgent":
      case "sellerSolicitor":
        await participantsApi.putParticipant(
          workspaceId,
          participant.id!,
          participant
        );
        break;
      case "buyer":
      case "buyerSolicitor":
        await offersApi.putOfferParticipant(
          workspaceId,
          offerId!,
          participant.id!,
          participant
        );
        break;
      default:
        break;
    }
  }
);

export const addSeller = createAsyncThunk<number, number>(
  "contractStepper/addSeller",
  async (workspaceId) => {
    const participant: Participant = {
      role: "seller",
    };
    const response = await participantsApi.postParticipant(
      workspaceId,
      participant
    );
    return response.data.id!;
  }
);

export interface RemoveParticipantArgs {
  contractId: number;
  participantId: number;
}
export const removeSeller = createAsyncThunk<number, RemoveParticipantArgs>(
  "contractStepper/removeSeller",
  async ({ contractId: workspaceId, participantId }) => {
    await participantsApi.deleteParticipant(workspaceId, participantId);
    return participantId;
  }
);

export const getPdf = createAsyncThunk<string, number>(
  "contractStepper/getPdf",
  async (workspaceId) => {
    const oidcStorage = sessionStorage.getItem(
      `oidc.user:${authority}:${client_id}`
    );
    const user = User.fromStorageString(oidcStorage!);

    const response = await axios.get(
      `${konvei_api}/workspaces/${workspaceId}/contract/pdf`,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(response.data);

    return url;
  }
);

export const completeContractDrafting = createAsyncThunk<void, number>(
  "contractStepper/completeContractDrafting",
  async (workspaceId) => {
    await contractsApi.completeContractDrafting(workspaceId);
  }
);

export interface UploadAnnexureArgs {
  workspaceId: number;
  annexure: File;
}

export interface UpdateAnnexureArgs {
  workspaceId: number;
  annexureId: number;
  position: number;
}

export interface DeleteAnnexureArgs {
  workspaceId: number;
  annexureId: number;
}

export const uploadAnnexure = createAsyncThunk<void, UploadAnnexureArgs>(
  "contractStepper/uploadAnnexure",
  async ({ workspaceId, annexure }) => {
    await contractsApi.addAnnexure(
      workspaceId,
      annexure.name,
      PutDocumentDocumentTypeEnum.Annexure,
      annexure
    );
  }
);

export const updateAnnexure = createAsyncThunk<void, UpdateAnnexureArgs>(
  "contractStepper/updateAnnexure",
  async ({ workspaceId, annexureId, position }) => {
    await contractsApi.updateAnnexurePosition(
      workspaceId,
      annexureId,
      position
    );
  }
);

export const deleteAnnexure = createAsyncThunk<void, DeleteAnnexureArgs>(
  "contractStepper/deleteAnnexure",
  async ({ workspaceId, annexureId }) => {
    await contractsApi.deleteAnnexure(workspaceId, annexureId);
  }
);

export const getCurrentUserDetails = createAsyncThunk<Participant, void>(
  "contractStepper/getCurrentUserDetails",
  async () => {
    const response = await teamsApi.getMyDetails();
    return response.data.user;
  }
);

export interface OfferParticipantArgs {
  offerId: number;
  contractId: number;
  participantId: number;
}
export const deleteBuyer = createAsyncThunk<number, OfferParticipantArgs>(
  "contractStepper/deleteBuyer",
  async ({ contractId, offerId, participantId }) => {
    await offersApi.deleteOfferParticipant(contractId, offerId, participantId);
    return participantId;
  }
);
