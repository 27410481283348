import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { addressSearchApi } from "../api";
import {
  FlattenedAddress,
  FlattenedAddresses,
  Participant,
  PhysicalAustralianAddress,
} from "../generated";
import { useDebounce } from "usehooks-ts";
import { Control, Controller, UseFormSetValue } from "react-hook-form";

export interface Address {
  streetAddress1?: string;
  streetAddress2?: string;
  stateOrTerritory?: string;
  locality?: string;
  postCode?: string;
}

export type OnAddressChanged = (address: Address) => void;

export interface AddressFormProps {
  control: Control<Participant>;
  setValue: UseFormSetValue<Participant>;
  address: Participant;
  disabled?: boolean;
}

const AddressForm: React.FC<AddressFormProps> = ({
  control,
  setValue,
  disabled,
}) => {
  const [inputAddress, setInputAddress] = useState("");
  const [lookupProperty, setLookupProperty] = useState<Array<FlattenedAddress>>(
    []
  );
  const debouncedAddress = useDebounce<string>(inputAddress, 1000);

  useEffect(() => {
    if (debouncedAddress !== undefined && debouncedAddress !== "") {
      lookupAddress(debouncedAddress);
    }
  }, [debouncedAddress]);

  async function lookupAddress(searchTerm: string): Promise<void> {
    const response = await addressSearchApi.searchAddress(searchTerm);
    if (response.status === 200) {
      const items = response.data as FlattenedAddresses;
      setLookupProperty(items.items!);
    }
  }

  async function handleAddressSelected(cid: string): Promise<void> {
    const deets = await addressSearchApi.getAddress(cid);
    setValue("streetAddress1", generateStreetAddress1(deets.data), {
      shouldDirty: true,
    });
    setValue("locality", deets.data.structured.locality!.name, {
      shouldDirty: true,
    });
    setValue("postCode", deets.data.structured.postcode, { shouldDirty: true });
    setValue("stateOrTerritory", deets.data.structured.state?.abbreviation, {
      shouldDirty: true,
    });
  }
  const generateStreetAddress1 = (
    address: PhysicalAustralianAddress
  ): string => {
    let streetAddress1 = "";
    if (address.structured.buildingName) {
      streetAddress1 = `${address.structured.buildingName},`;
    }
    if (address.structured.flat?.number) {
      streetAddress1 = ` ${streetAddress1}${address.structured.flat.type?.name} ${address.structured.flat?.number}, `;
    }

    if (address.structured.street?.name) {
      streetAddress1 = ` ${streetAddress1}${address.structured.number?.number} ${address.structured.street?.name} ${address.structured.street?.type?.name}`;
    }
    return streetAddress1;
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12}>
        <Autocomplete
          freeSolo
          id="address"
          fullWidth
          size="small"
          options={lookupProperty.map<FlattenedAddress>((m) => m)}
          filterOptions={(x) => x}
          onInputChange={(_e, n) => setInputAddress(n)}
          disabled={disabled}
          onChange={(_event, addr) => {
            if (addr === null) {
              return;
            }
            const x = addr as FlattenedAddress;
            handleAddressSelected(x?.pid!);
          }}
          isOptionEqualToValue={(a, b) => a.pid === b.pid}
          getOptionLabel={(o) => {
            const addr = o as FlattenedAddress;
            return addr.sla;
          }}
          renderInput={(params) => (
            <TextField
              variant="filled"
              {...params}
              label="look up an address"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="streetAddress1"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              variant="filled"
              fullWidth
              size="small"
              label={"Street Address 1"}
              required
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              disabled={disabled}
              InputLabelProps={{
                shrink: field.value?.length! >= 1,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="streetAddress2"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              variant="filled"
              fullWidth
              size="small"
              label={"Street Address 2"}
              required
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              disabled={disabled}
              InputLabelProps={{
                shrink: field.value?.length! >= 1,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={7}>
        <Controller
          name="locality"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              variant="filled"
              fullWidth
              size="small"
              label={"Locality"}
              required
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              disabled={disabled}
              InputLabelProps={{
                shrink: field.value?.length! >= 1,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <Controller
          name="stateOrTerritory"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              variant="filled"
              fullWidth
              size="small"
              label={"State or Territory"}
              required
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              disabled={disabled}
              InputLabelProps={{
                shrink: field.value?.length! >= 1,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} sm={2}>
        <Controller
          name="postCode"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              variant="filled"
              fullWidth
              size="small"
              label={"Post Code"}
              required
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              disabled={disabled}
              InputLabelProps={{
                shrink: field.value?.length! >= 1,
              }}
            />
          )}
        />
      </Grid>
    </React.Fragment>
  );
};

export default AddressForm;
